import React, { useEffect, useState, useRef } from "react";
import styled from 'styled-components/macro';
import ArrowDropDownIcon from '@material-ui/icons/ExpandMore';
import ArrowUpIcon from '@material-ui/icons/ExpandLess';
import { useDispatch } from 'react-redux';

const StyledRoot = styled.div`    
    margin-left: 0em;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledLabel = styled.div` 
    display: flex;    
    vertical-align: top;
    width: 100%;
`;

const LabelText = styled.div`
    color: ${ props => props.theme.label };
    font-size: medium;
    margin-left: .5em;  
    vertical-align: top;
    padding-bottom: 1em;
`;

const RequiredSymbol = styled.div`
    color: red;
    padding-left: .2em; 
`;

const StyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 3em; 
`;

const StyledEnterBoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 15px;  
`;

const StyledDropdownContainer = styled.div`
    position: relative;
    background: #fcfcfc 0% 0% no-repeat padding-box;
    box-shadow: 0px 25px 40px ${props => props.theme.formShadow};
    border-radius: 18px;
    opacity: 1;
    padding-bottom:10px;
    padding-top:0.2em;
    z-index: 2;
`;

const HelperText = styled.div`
    color: ${ props => props.theme.error };
    margin-left: .5em;
    margin-top: .5em;
    font-size: .75em;
    min-height: 1.25em;
`;

const StyledDropdownBox = styled.div`
  border-radius: 18px;
  padding-left: 20px; 
  padding-top: .5em;
  padding-bottom: .5em;
  background-color: #fcfcfc; 
  cursor: pointer;
  &:focus{
    border: none;
    outline: none;
  }
`;

const StyledDropdownList = styled.span`
  font-size: 0.8em;
  opacity: 0.9;
  tableIndex: 0;
  border-radius: 18px;
  &:hover {
    opacity: 1;
    background-color: #d8eddd;
  }
`;

const TextArrowWrapper = styled.div`
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 40px ${props => props.theme.formShadow};
  border-radius: 15px;
  opacity: 1;
  height: 3em;
  justify-content: space-between;
  display: flex;
  z-index: 2;
`;

const StyledArrow = styled.div` 
  cursor: pointer;
  display: flex;
  padding-right: 1em;
  flex-direction: column;
  justify-content: center;
`;

const StyleLabelText = styled.div`
   color: ${ props => props.theme.label };
   font-weight: bold;
`;

const SelectedOptionDisplay = styled.div`
  color: ${props => props.theme.label};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1em;
  height: 3em;
  padding-right: 1em;
`;

const AutocompleteComponent = (props) => {
  let options = props.selectionOption;  
  
  const [display, setDisplay] = useState(false);
  const [value, setValue] = useState(props.value==null ? '' : props.value);
  const [isValidValue, setIsValidValue] = useState(props.isValid);
  const [isMadeSelection, setIsMadeSelection] = useState(false);
  const wrapperRef = useRef(null); 
  const dispatch = useDispatch();
  
  let isValueOk = props.isValid;
  let isError = props.error;
  let isSubmit = props.isSubmit == null ? false : props.isSubmit; //for click on submit button 
  let dropdownWith =  props.width;
  
  // if dropdown list is more than 12 items, show scrolling and adjust the width
  if(options.length > 12 && dropdownWith !== '100%'){
    dropdownWith = dropdownWith - 20;
  }
 
  useEffect(() => {
      window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

const validateSelection = (v) => {
    let isMatch = false;
    
    for(let i =0; i<options.length; i++){
      if(v.toLowerCase() === options[i].toLowerCase()){
        isMatch = true; 
        break;
      }
    }
   return isMatch;
  }
 
  const handleClickOutside = event => {
    const { current: wrap } = wrapperRef;
   
    if (wrap && !wrap.contains(event.target)) {
        if((props.required && value !== '' && validateSelection(value)) || 
          (!props.required && (value === '' || validateSelection(value)) )){
          setIsValidValue(true); 
          setIsMadeSelection(true);        
        }
        else  {
          setIsValidValue(false);
        }        
        setDisplay(false); 
      
        props.onChange(value);           
    }   
  };

  const getPrompts = (prompts) =>{
 
    if(!prompts){
      return '';
    }
   
    return(
      prompts.map((prompt, i) =>{
          return (<div key={'prompt_'+i} variant="body1" color="inherit">{prompt.value}</div>);
      })
    );
  }

  const updateInput = input => {    
    setValue(input);
    setDisplay(false);  
    setIsMadeSelection(true);   

    if(props.question != null){
      props.onChange({ answer: input, uuid: props.question.uuid, id: props.question.questionID}); 
    }
    else{
      props.onChange(input);
    }
  };
  
  const handleTextFieldOnClick = () => {  
    setDisplay(!display);        
    setIsValidValue(null);   
  }

 const handleArrowOnClick = () =>{  
  setDisplay(!display);        
  setIsValidValue(null); 
 }

  const renderArrow = () =>{

    if(!display){
      return (
        <ArrowDropDownIcon onClick={handleArrowOnClick} />
      )
    }
    else{
      return (
        <ArrowUpIcon onClick={handleArrowOnClick} />
      )
    }
  }

  if (isValidValue != null) {
    isError = !isValidValue;
    isValueOk = isValidValue;
  }
  else{
      isValueOk = false;    
  }
  //prevent from show error when first click anywhere
  if(!isSubmit && !isMadeSelection){
    isError = null;
    isValueOk = null;
  }

  return (
    <StyledRoot className={props.className}> 
        <StyledLabel>
              <LabelText >
                <StyleLabelText>
                  { props.label ? props.label : getPrompts(props.question ? props.question.prompts : '') }
                </StyleLabelText>
              </LabelText>
                { props.required ? <RequiredSymbol>*</RequiredSymbol> : '' }             
        </StyledLabel>
        <StyledWrapper id={props.id} onClick={handleTextFieldOnClick}>             
                <StyledEnterBoxWrapper ref={wrapperRef}>                    
                  <TextArrowWrapper>
                    <SelectedOptionDisplay>
                      {value ? value : props.placeholder}
                    </SelectedOptionDisplay>
                    <StyledArrow> {renderArrow()} </StyledArrow>
                  </TextArrowWrapper>  
                  {display && (                     
                      <StyledDropdownContainer>                    
                        {options
                          .map((v, i) => {
                            return (
                              <StyledDropdownBox
                                onClick={() => updateInput(v)}                               
                                key={i}
                                tabIndex="0"
                              >
                              <StyledDropdownList >{v}</StyledDropdownList>                 
                              </StyledDropdownBox>
                            );
                          })}
                      </StyledDropdownContainer>
                     )} 
                
                </StyledEnterBoxWrapper>                  
          </StyledWrapper>  
          <HelperText> {!isValueOk && isError!=null && isError===true ? props.helperText : '' } </HelperText>     
    </StyledRoot> 
  );
};

export default AutocompleteComponent;
