import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import QuestionParentComponent from '../QuestionParentComponent/QuestionParentComponent';
import { up } from 'styled-breakpoints';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;  
  margin-right: 2em;
  margin-left: 2em;   
  ${up('lg')} {
      margin-right: 0;
      margin-left: 0;
  }
`;

const QuestionRow = styled.div`
    display: flex;    
    flex-wrap: wrap;
`;

const QuestionContainer = styled.div`
    width: 100%;
    padding-bottom: 80px;
    display: flex;
    justify-content: center;
`;

const SectionComponent = (props) => {
    const selectedSection = useSelector((state) => state.selectedSection);
   
    const renderQuestions = () => {
        const pageComponents = selectedSection.questions.map((v, i) => {  
            if(v.type !== 'noAnswer'){
                return(
                    <QuestionContainer key={`q_container_${i}`} spacing={selectedSection.type}>
                        <QuestionParentComponent key={v.uuid} question={v}></QuestionParentComponent>
                    </QuestionContainer>
                )
            }else{
                return(
                    <></>
                )
            }                              

        })
       
        return pageComponents;
    }

    const renderSection = () => {
        return(
            //<QuestionRow style={{justifyContent: isSmallScreen ? 'center' : 'space-between', paddingLeft: isSmallScreen ? '2em' : 0, paddingRight: isSmallScreen ? '2em' : 0}}>
            <QuestionRow>
                {renderQuestions()}
            </QuestionRow>
        )
    }

    return (      
        <StyledRoot> 
            {renderSection()}
        </StyledRoot>
    );        
}

export default SectionComponent;