import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from '../../Themes/themes';
import GlobalStyles from '../GlobalStyles/GlobalStyles';
import ScreeningContainerComponent from '../ScreeningContainerComponent/ScreeningContainerComponent';
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import FooterComponent from '../FooterComponent/FooterComponent';
import configs from '../../configuration/configuration.json';
import { getClient } from '../../util/helper/Helper';
import { Helmet } from 'react-helmet';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${props => props.theme.background};
  min-height: 100vh;
  &:focus{
    outline: none;
  }
  background-color: blue;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${props => props.theme.background};
  width: 100%;
`;

const HomeComponent = () =>{ 
  const globalState = useSelector((state) => state.global);
  const sections = useSelector((state) => state.sections);
  const dispatch = useDispatch();
  const selectedSection = useSelector((state) => state.selectedSection);
  const [showTransitionPage, setShowTransitionPage] = React.useState(null); 

  useEffect(() => {
    dispatch({type: 'SET_CONFIG', theme: getClient().toLowerCase()})
    dispatch({type: 'SET_THEME', theme: getClient().toLowerCase()})
  }, [globalState.flags])

  if(!selectedSection && sections.length > 0){
    dispatch( {type: 'SET_SECTION', data: sections[0]} );
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0
    });
  }

  if(selectedSection && selectedSection.rendering){
    scrollToTop();
    dispatch( {type: 'UPDATE_SECTION_RENDERING' });

    if(!selectedSection.showTransition){
      dispatch({type: 'INIT_SECTION', data: selectedSection});  
    }
  }

  const handleTransitionStart = (e) => {
    setShowTransitionPage(false);
   
    if(selectedSection.isTimed){
      dispatch({type: 'SET_TIMER', data: { started: true, timeRemaining: selectedSection.timeRemaining*1000, timeLeft: { minutes: selectedSection.timeRemaining / 60, seconds: 0 }, startTime: Date.now(), minitLimit: selectedSection.timeRemaining / 60 }})
    }
    dispatch({type: 'INIT_SECTION', data: selectedSection});
  }

  const handleTransitionBack =(e) =>{

    setShowTransitionPage(false);
    dispatch({type: 'INIT_SECTION', data: e});
  }

  const handleTransitionNext = (isFinalSection) => {
    if(!isFinalSection){
      setShowTransitionPage(null);
    }
  }

  const imgBasePath = process.env.REACT_APP_env_img_base_path;

  useEffect(() => {
    if(selectedSection){
      if(selectedSection.showTransition && showTransitionPage === null){
        setShowTransitionPage(selectedSection.showTransition);
      }
    }
  }, [selectedSection, showTransitionPage])

  if(globalState && globalState.completed){
    return(
      <Redirect to='/thankyou' />
    );
  }else{
    return (
      <ThemeProvider theme={ themes[globalState.theme]}>   
        <StyledRoot>
          <Helmet>
            <title>Catalyte screening</title>
          </Helmet>
            <GlobalStyles></GlobalStyles>
            <HeaderComponent bannerImageUrl={selectedSection ? selectedSection.bannerImageUrl : null} logoImageUrl={imgBasePath + globalState.config.logo}/>    
            <StyledWrapper>
              <ScreeningContainerComponent selectedSection={selectedSection} sections={sections} global={globalState} showTransitionPage={showTransitionPage} handleTransitionStart={handleTransitionStart} handleTransitionNext={handleTransitionNext} handleTransitionBack={handleTransitionBack}/>
            </StyledWrapper>
            <FooterComponent /> 
        </StyledRoot>
       </ThemeProvider>    
    );
  }
}

export default HomeComponent;
