import configs from '../../../configuration/configuration.json';

export const initialState = {
  completed: false,
  showRequiredModal: false,
  showLoadingSpinner: false,
  theme: 'default',
  config: configs['default'],
};

export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case 'COMPLETED_SCREENING':
      return { ...state, completed: true, showRequiredModal: false }
    default:
      return state;
    case 'TOGGLE_REQUIRED_MODAL':
      return { ...state, showRequiredModal: !state.showRequiredModal }
    case 'TOGGLE_LOADING_SPINNER':
      return { ...state, showLoadingSpinner: action.data.showLoadingSpinner }
    case 'COGNITO_USER':
      return {...state, cognitoUser: action.data}
    case "SET_GO_REGISTER":
      return { ...state, goRegister: action.data }
    case 'SET_GO_HOME':
      return {...state, goHome: action.data}
    case "SET_FLAGS":
      return {...state, flags: action.flags}
    case 'SET_THEME':
      switch(action.theme){
        case 'dark':
          if(state.flags && state.flags.darktheme){
            return {...state, theme: 'dark'};
          }else{
            return {...state, theme: 'default'}
          }
        case 'ravens':
          if(state.flags && state.flags.ravenstheme){
            return {...state, theme: 'ravens'}
          }else{
            return {...state, theme: 'default'}
          }
        case 'createoppcolumbia':
          return {...state, theme: 'createoppcolumbia'}
        default:
          return {...state, theme: 'default'};
    }
    case 'SET_CONFIG':
      switch(action.theme){
        case 'dark':
          if(state.flags && state.flags.darktheme){
            return {...state, config: configs['dark']}
          }else{
            return {...state, config: configs['default']}
          }
        case 'ravens':
          if(state.flags && state.flags.ravenstheme){
            return {...state, config: configs['ravens']}
          }else{
            return {...state, config: configs['default']}
          }
        case 'createoppcolumbia':
          return {...state, config: configs['createoppcolumbia']}
        default:
          return {...state, config: configs['default']};
    }
  }
}
