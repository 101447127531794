import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';
import SecondaryButtonComponent from '../SecondaryButtonComponent/SecondaryButtonComponent';
import SaveExitButtonComponent from '../PrimaryButtonComponent/SaveExitButtonComponent';
import { useMediaQuery } from 'react-responsive';
import { up } from 'styled-breakpoints';

const TransitionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 25px;
  font-weight: bold;
  color: ${props => props.theme.accentText};
  align-items: center;
  width: 70%;
  max-width: 1300px;
`;

const MainColumn = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5em;
`;

const HorizontalCenteringDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 2em;
  width: 100%;

  ${up('lg')} {
    margin: 0 auto;
    width: 70%;    
    max-width: 1300px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  font-size: 14px;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-right: 2em;
  ${up('lg')} {
    padding-right: 0;
  }
  width: 100%;
  justify-content: left;
}
`;

const HelperTextWrapper = styled.div`
  max-width: fit-content;
`;

const SecondaryTextWrapper = styled.div`
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  overflow: hidden;
  color: ${props => props.theme.primaryText};
  opacity: 1;
`;

const ExampleImageWrapper = styled.div`
`;

const ExampleImage = styled.img`
  max-width: 100%;
`;

const SectionTimeWrapper = styled.div `
  display: flex;
  position: relative;
  top: -50px;
  width: 100%;
`;

const TimeAllowed = styled.div`
  width: 5em;
  margin: .2em;
  opacity: 1;
`;

const TimerIcon = styled.div`
  width: 1.6em;
  height: 1.6em;
  background-image: url(./images/SVGTemplates/time.svg);
  background-size: cover;
  margin-right: .5em;
`;

const StyledSaveNExitButton = styled(SaveExitButtonComponent)`
  margin: 0.1em;   
`;

const ButtonStyle = styled.div`   
   margin-left: 50%;   
`;

const SectionTransitionComponent = (props) => {
  const selectedSection = useSelector((state) => state.selectedSection);
  const isBigScreen = useMediaQuery({ query: '(min-width: 1160px)' });
  const dispatch = useDispatch();
  const sections = props.sections;
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0
    });
  }

  scrollToTop();

  const getPreviousAvailableSection = () => {
    let availableSections = sections.filter(s => s.order < selectedSection.order && !(s.isTimed && s.startTime));
    
    if(availableSections.length > 0){
      return availableSections[availableSections.length - 1];
    }else{
      return null;
    }
  }
 
  const onPreviousSection = () =>{   
    let previousAvailableSection = getPreviousAvailableSection();    
    props.onTransitionBack(previousAvailableSection);
 }

  const onSaveAndExit = (e) => {
    dispatch({type: 'LOG_OUT', data: { selectedSection: selectedSection, signOut: true }});
  }

  return (
    <HorizontalCenteringDiv>
      <TransitionWrapper>
        {selectedSection.isTimed ? <SectionTimeWrapper>
          <TimerIcon></TimerIcon>
          <TimeAllowed>{Math.floor(selectedSection.timeAllowed / 60) + ":" + ((selectedSection.timeAllowed % 60) >= 10 ? (selectedSection.timeAllowed % 60) : (selectedSection.timeAllowed % 60) + "0")}</TimeAllowed>
        </SectionTimeWrapper> : ''}
        <MainColumn style={{flexDirection: isBigScreen ? 'row' : 'column'}}>
          <SecondaryTextWrapper style={{paddingBottom: '2em'}}>{selectedSection.secondaryText}</SecondaryTextWrapper>
          <ExampleImageWrapper>
            <ExampleImage src={selectedSection.imageUrl} ></ExampleImage>
          </ExampleImageWrapper>
        </MainColumn>
        <ButtonWrapper>
          <HelperTextWrapper></HelperTextWrapper>          
          <SecondaryButtonComponent onClick={onPreviousSection} gradientBorder={true} text={'Back'}></SecondaryButtonComponent>
          <ButtonStyle>           
            <PrimaryButtonComponent onClick={props.onTransitionStart} disabled={false} text={'Start'}></PrimaryButtonComponent>
            <StyledSaveNExitButton onClick={onSaveAndExit} text={'Save & Exit'}></StyledSaveNExitButton>        
          </ButtonStyle>
        </ButtonWrapper>
      </TransitionWrapper>
    </HorizontalCenteringDiv>
  );
}

export default SectionTransitionComponent;