import React, {useEffect} from 'react';
import styled from 'styled-components/macro';

const imgBasePath = process.env.REACT_APP_env_img_base_path;

const StyledRoot = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledInput = styled.div`
    display: flex;
    height: 3em;
    background-color: ${props => props.theme.inputBackground};
    border-radius: 1em;
    justify-content: space-between;
    padding-left: 1em; 
`;

const StyledTextInput = styled.input`
    border: none;
    min-width: 0em;
    border-radius: 1em;
    font-size: 1em;
    background-color: inherit;
    flex-grow: 1;
    color: ${props => props.theme.inputText};
    &:hover{
        border: none;
    }
    &:focus{
        border: none;
        outline: none;
    }
`;

const LabelText = styled.div`
    color: ${ props => props.theme.label };
    font-weight: 600;
    font-size: medium;
    margin-left: 0; 
`;

const StyledLabel = styled.div`
    display: flex;
    min-height: 10px;
    padding-bottom: 1em; 
`;

const RequiredSymbol = styled.div`
    color: red;
    margin-left: .2em;
    margin-bottom: 0;    
`;

const ValidSymbol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: green;
    margin-right: .5em;
    background-image: url(${ props => props.theme.validIcon });
    width: 1em;
    background-repeat: no-repeat;
    background-position-y: center;   
`;

const ErrorSymbol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: green;
    background-image: url(${ props => props.theme.errorIcon });
    width: 1.5em;
    background-repeat: no-repeat;
    background-position-y: center;
`;

const HelperText = styled.div`
    color: ${ props => props.theme.error };
    margin-left: .5em;
    margin-top: .5em;
    font-size: .75em;
    min-height: 1.25em;    
`;

const ValidationSymbolWrapper = styled.div`
    width: 1.9em;
    display: flex;  
    position: relative;
    left: -1em;  
`;

const ShowpasswordWrapper = styled.div`
    width: 1.2em;
    display: flex;
    z-index: 10;  
`;

const ShowPassword = styled.div`    
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: green;
    background-image: url(${ imgBasePath + 'show-pw.png' });
    width: 1.2em;
    background-repeat: no-repeat;
    background-position-y: center;     
`;

const HidePassword = styled.div`    
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: green;
    background-image: url(${ imgBasePath + 'hide-pw.png' });
    width: 1.2em;
    background-repeat: no-repeat;
    background-position-y: center;     
`;

const IconWrapper = styled.div`
    display: flex;
    position: relative;
    right: -1em;  
`;

export const TextInputComponent = (props) => {
    const [fieldType, setFieldType]= React.useState(props.type);
    const [isFocus, setIsFocus]= React.useState(false);
    const [isEyeButton, setIsEyeButton] =React.useState(props.isEyeButton);
    const WrapperStyle = {};
    const inputStyle = {};
    const labelStyle ={};
    const wrapperRef = React.useRef(null); 

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    });

    const handleClickOutside = event => {
        const { current: wrap } = wrapperRef;   
       
        if (wrap && props.onEyeClick && (event.target.id === props.id + 'eye' || event.target.id === props.id)) { 
            setIsEyeButton(true); 
            setIsFocus(true);    
        } 
        else{
            if(props.onEyeClick)
              setFieldType('password');
            if(props.outsideClick)
            {
               props.outsideClick();
            }
            setIsEyeButton(false);           
            setIsFocus(false);              
        }
    };

    if(props.backgroudColor){
        WrapperStyle.backgroundColor = props.backgroudColor;
    }
  
    if(props.width){
         WrapperStyle.width = props.width;      
    }

    if(props.boxShadow){
        WrapperStyle.boxShadow = props.boxShadow;
    }

    if(props.textAlign){
        inputStyle.textAlign = props.textAlign;
    }
    
    if(props.color){
        inputStyle.color = props.color;
    }

    if(props.fontWeight){
        inputStyle.fontWeight = props.fontWeight;
    }

    const handleChange = (e) => {
        props.onChange(e.target.value, isFocus); 
    }

    const handleOnBlur = (e) => {  
        e.target.placeholder = props.placeholder ? props.placeholder : '';
        if(props.onBlur){
            props.onBlur(e.target.value);
        }
    }

    const handleOnEyeClice =() =>{         
        if(fieldType === 'password'){
            setFieldType('text');
        }
        else{
            setFieldType('password');
        }         
    }

    const handleOnFocus = (e) =>{ 
        e.target.placeholder='';
        setIsFocus(true);
        if(props.onFocus)
           props.onFocus(e.target.id);
    }

    return (
        <StyledRoot className={props.className} >
            {   !props.noLabelPlaceholder &&
                <StyledLabel style={labelStyle}>
                    {props.label ? <LabelText>{ props.label }</LabelText> : ''}
                    { props.required ? <RequiredSymbol>*</RequiredSymbol> : '' }
                </StyledLabel>
            }
            <StyledInput style={WrapperStyle} ref={ wrapperRef } >
                <StyledTextInput id={props.id} style={inputStyle} type={fieldType || 'text'} onChange={handleChange} onBlur={handleOnBlur} onFocus={handleOnFocus} value={props.value || ""} placeholder={props.placeholder} />
                    <IconWrapper>    
                        {isEyeButton && <ShowpasswordWrapper>
                        {fieldType === 'password' ? <ShowPassword id={props.id+'eye'} onClick ={handleOnEyeClice}></ShowPassword> : 
                        <HidePassword id={props.id+'eye'} onClick ={handleOnEyeClice}></HidePassword> }</ShowpasswordWrapper> }
                        {!isFocus ? <ValidationSymbolWrapper>                  
                            { props.isValid ? <ValidSymbol></ValidSymbol> : '' }
                            { props.error ? <ErrorSymbol></ErrorSymbol> : '' }                   
                        </ValidationSymbolWrapper> : <ValidationSymbolWrapper /> }  
                    </IconWrapper>                   
            </StyledInput>
            <HelperText> { props.error ? props.helperText : '' } </HelperText>           
        </StyledRoot>
    );
};

export default TextInputComponent;