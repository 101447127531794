import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import TextInputComponent from '../TextInputComponent/TextInputComponent';
import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';
import SecondaryButtonComponent from '../SecondaryButtonComponent/SecondaryButtonComponent';
import RadioButtonComponent from '../RadioButtomComponent/RadioButtonComponent';
import { ThemeContext } from 'styled-components';

const FormBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.card};
  padding: 1em;
`;

const Paragraph = styled.p`
    color: ${props => props.theme.primaryText};
    margin-bottom: 20px;
`;

const InputGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const RadioButtons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 15em;
`;

const ButtonRow = styled.div`
  margin-left: 1em;
  margin-right: 1em;
  justify-content: space-between;
  display: flex;
  padding-bottom: 4em;
  padding-top: 4em;
`;

const RadioButtonRow = styled.div`
    margin-top: 1em;
    display: flex;
`;

const HelperText = styled.div`
    color: ${ props => props.theme.error };
    margin-left: 1.5em;
    margin-top: .17em;
    font-size: .75em;
    min-height: 1.25em;
`;

const TermTitleWrapper = styled.div` 
    display: flex;
    color: ${props => props.theme.accentText};
    font-weight: bold;
`;

const RequiredSymbol = styled.div`
    color: red;  
    margin-bottom: 0;
    padding-left: 0.5em;
`;

const BoldStyle = styled.div`
    font-weight: bold; 
`;
const Paragraphs = styled.div`
`;

const MobileSecondaryFormComponent = (props) =>{
    const themeContext = useContext(ThemeContext);
    const isSubmitAction = props.isSubmitAction;
    const onAboutUsChange = props.onAboutUsChange;
    const hearAboutUsList = props.hearAboutUsList;
    const aboutUs = props.aboutUs;
    const isValidAboutUs = props.isValidAboutUs;
    const isValidPassword = props.isValidPassword;
    const getPasswordHelperText = props.getPasswordHelperText;
    const password = props.password;
    const onPasswordChange = props.onPasswordChange;
    const onPasswordBlur = props.onPasswordBlur;
    const isValidConfirmedPassword = props.isValidConfirmedPassword;
    const confirmedPassword = props.confirmedPassword;
    const onConfirmedPasswordChange = props.onConfirmedPasswordChange;
    const onConfirmedPasswordBlur = props.onConfirmedPasswordBlur;
    const consent = props.consent;
    const onConsentChange = props.onConsentChange;
    const isValidConsent = props.isValidConsent;
    const getConsentHelperText = props.getConsentHelperText;
    const handleBack = props.handleBack;
    const isValid = props.isValid;
    const handleSubmit = props.handleSubmit;
    const onEyeClick = props.handleEyeClick;
    const client = props.client;
    const showPwd = props.globalState.cognitoUser;

    return (
        <FormBox>
            {!showPwd &&
            <InputGroup>
            
                <TextInputComponent
                    id={'pwd-input'}
                    error={isValidPassword === null ? false : !isValidPassword}
                    helperText={getPasswordHelperText(password)}
                    type={props.passwordType} 
                    onChange={onPasswordChange}
                    isEyeButton = {props.isShowPWEyeButton}
                    value={password}
                    onBlur={onPasswordBlur}
                    onEyeClick ={onEyeClick}
                    onFocus ={props.onPWFocus} 
                    required={true}
                    isValid={isValidPassword}
                    label='Password'
                    backgroudColor={themeContext.quarterly}
                />
                <TextInputComponent
                    id={'cnfrm-input'}
                    error={ isValidConfirmedPassword === null ? false : !isValidConfirmedPassword }
                    helperText={ confirmedPassword === null ? 'You must confirm your password.' : 'Passwords do not match.' }
                    type ={props.confirmPasswordType}
                    isEyeButton = {props.isShowCPWEyeButton}
                    onChange={onConfirmedPasswordChange}
                    value={confirmedPassword}
                    onBlur={onConfirmedPasswordBlur}
                    onEyeClick ={onEyeClick}                   
                    onFocus = {props.onCPWFocus}
                    required={true}
                    isValid={isValidConfirmedPassword}
                    label='Confirm Password'
                    backgroudColor={themeContext.quarterly}
                />
                { client != 'createoppcolumbia' ?             
                <TextInputComponent
                    id={'about-us-ddl'}
                    onChange={onAboutUsChange}                   
                    value={aboutUs}                                
                    label ={"How did you hear about us?"}                          
                    onBlur={onAboutUsChange}                   
                    backgroudColor={themeContext.quarterly}
                /> : '' }             
            </InputGroup>
            }
            <TermTitleWrapper>Terms and Conditions<RequiredSymbol>*</RequiredSymbol></TermTitleWrapper>
            { client === 'createoppcolumbia' ? 
            <Paragraphs>    
            <Paragraph>                           
                 By signing this application, you also agree not to discuss the contents of the screening you take at Catalyte with others. In addition, you authorize Catalyte to 1) verify any information included in this application, 2) contact previous employers, educators, and personal references, 3) perform a criminal background check on you, and 4) use your information for statistical purposes, which may include information about your future earnings but which will not include release of any information that could be used to identify you. 
            </Paragraph>
            <Paragraph> 
            <BoldStyle>
            Furthermore, you agree not to hold Catalyte liable for any damages or losses incurred as a result of applying to Catalyte, as a result of this application process, or as a result of rejection from or admission into its program.
            </BoldStyle></Paragraph>
            </Paragraphs> :
            <Paragraphs>
             <Paragraph>                           
                During training and prior to employment, you will be expected to dedicate 30-40 hours per week to this program for as long as 16 - 26 weeks. If you successfully complete training you will be employed in a full-time capacity, starting at 9:00 am each morning for at least 40 hours per week.
             </Paragraph>           
             <Paragraph>
            By signing this application, you agree not to disclose to third parties the contents of the proprietary screening test administered by Catalyte, unless permitted by law. In addition, you acknowledge that, subject to applicable law, you may be required to (1) verify information included in this application, (2) submit to testing for illegal drugs, (3) submit to a criminal background check, (4) submit to other background checks or verifications, which may involve contact with prior employers, educators, and/or personal references, and (5) provide information about prior and future earnings to be used for statistical purposes related to the effectiveness of the program, but which will not be released in any manner that could be used to identify you. 
            </Paragraph>          
            <Paragraph> 
            <BoldStyle>
            Furthermore, to the maximum extent permitted by applicable law, you agree not to hold Catalyte liable for any damages or losses incurred as a result of the application process or as a result of rejection from or admission into Catalyte’s program.
            </BoldStyle></Paragraph>
            </Paragraphs>
            }
            <RadioButtonRow>
                <RadioButtons>
                    <RadioButtonComponent id="agree" onChange={onConsentChange} group="consent" label="I accept all terms and conditions" checked={consent === "agree"} isSelectedUnbold ={true} labelStyle={ {fontSize : 'medium', fontWeight : 600}} />                    
                </RadioButtons>
                <HelperText>{isValidConsent === false ? getConsentHelperText(consent) : ''}</HelperText>
            </RadioButtonRow>
            <ButtonRow>
                <SecondaryButtonComponent
                    id={'back-btn'}
                    gradientBorder={true}
                    onClick={handleBack}
                    size='small'
                    text={'Back'}
                ></SecondaryButtonComponent>
                <PrimaryButtonComponent
                    id={'submit-btn'}
                    innactive={!isValid}
                    size='small'
                    onClick={handleSubmit}
                    text={'Submit'}
                    className={'g-recaptcha'}
                    data-sitekey='6Lfvaz4aAAAAAKj9Lvx8KB-3AqEGt3TrbTVc-x3C'
                    data-callback={handleSubmit}
                    data-action='submit'
                ></PrimaryButtonComponent>
            </ButtonRow>
        </FormBox>
      );
  }

export default MobileSecondaryFormComponent;