import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Auth } from 'aws-amplify';
import { ThemeProvider } from 'styled-components';
import themes from '../../Themes/themes';
import GlobalStyles from '../../components/GlobalStyles/GlobalStyles';
import RegistrationComponent from '../../components/RegistrationComponent/RegistrationComponent';
import MobileRegistrationComponent from '../../components/RegistrationComponent/MobileRegistrationComponent';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getClient } from '../../util/helper/Helper';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const RegistrationContainer = () =>{
    const flags = useFlags();
    const globalState = useSelector((state) => state.global);
    const regState = useSelector((state) => state.registration);
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });

    const [currentForm, setCurrentForm] = React.useState(window.location.pathname === '/createoppcolumbia'  ? 'overview' : 'primary');
    const [userPass, setUserPass] = React.useState("");
    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('token');
    const oppToken = process.env.REACT_APP_env_createopp_client_token;

    useEffect(() => {
      dispatch({type: 'SET_CONFIG', theme: getClient().toLowerCase()})
      dispatch({type: 'SET_THEME', theme: getClient().toLowerCase()})
    }, [globalState.flags])

    useEffect(() => {
      if(globalState.config.name !== 'default'){
        setCurrentForm('overview')
      }
    }, [globalState.config])

    useEffect(() => {
      Auth.currentAuthenticatedUser()
      .then(data => {
         dispatch({type: 'COGNITO_USER', data: data});
         let firstName = data.attributes.given_name || regState.firstName;
         let lastName = data.attributes.family_name || regState.lastName;
         let email = data.attributes.email;
         dispatch({ 'type': 'UPDATE_REGISTRATION_STATE', data: {...regState, firstName, lastName, email } });   
      })
      .catch(err => console.log(err));
    }, [])

    useEffect(() => {
      dispatch({type: 'SET_FLAGS', flags})
    }, [flags])

    useEffect(() => {
      let client = getClient();
      dispatch({ 'type': 'UPDATE_REGISTRATION_STATE', data: {...regState, client } });   
    }, [])

    useEffect(() => {
      if(getClient() === 'createoppcolumbia'){
        token = oppToken;
      }
        
      if(token){
        dispatch({ 'type': 'UPDATE_REGISTRATION_STATE', data: {...regState, token } });
      }
      
    }, [token]);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0
      });
    }

    useEffect(() => {
      scrollToTop();
    }, [currentForm])

    useEffect(() => {
      dispatch({ type: 'SET_GO_REGISTER', data: false });
    },[])

    if(globalState.goHome){
      return (
        <Redirect to='/welcome'/>
      )
    }

    const onClickSubmit = function(data){  
      dispatch({ 'type': 'UPDATE_REGISTRATION_STATE', data });
      switch(currentForm){
        case "overview":
          setCurrentForm("primary");
          break;
        case "primary":
          setCurrentForm("secondary");
          break;
        case "secondary":
            if(globalState && globalState.cognitoUser){
              dispatch({'type': 'REGISTER_USER_BACKEND', data: { ...regState, ...data }});
            }else{
              setUserPass(data.password);
              delete regState.password;     
              dispatch({'type': 'REGISTER_USER_COGNITO', data: { ...regState, ...data }});
              setCurrentForm("confirmation");
            }
          break;
        case "confirmation":
          if(data.cc){
            const confData = Object.assign({}, regState);
            confData.cc = data.cc;
            confData.password = userPass;
            dispatch({type: 'USER_CONFIRMING_COGNITO', confData });
          }
          break;

        default:
          break;
      }
    }

    const onClickBack = function(data){
      dispatch({ 'type': 'UPDATE_REGISTRATION_STATE', data });
      switch(currentForm){
        case "primary":
        case "overview":
          break;
        case "secondary":
          setCurrentForm("primary");            
          break;
        case("confirmation"):
          setCurrentForm("password");
          break;

        default:
          break;
      }
    }

    let componentProps = {...regState};
    componentProps.onClickSubmit = onClickSubmit;
    componentProps.onClickBack = onClickBack;
    componentProps.globalState = globalState;
    componentProps.currentForm = currentForm;
    componentProps.isSmallScreen = isSmallScreen;
    componentProps.showLoadingSpinner = globalState.showLoadingSpinner;

    return (
    <ThemeProvider theme={ themes[globalState.theme] }>
        <GlobalStyles />
        <StyledRoot tabIndex={-1}>
            <Helmet>
              <title>Catalyte screening registration</title>
            </Helmet>
            {isSmallScreen ? <MobileRegistrationComponent {...componentProps}></MobileRegistrationComponent> : <RegistrationComponent {...componentProps}></RegistrationComponent>}
        </StyledRoot>
    </ThemeProvider>
    );
  }

export default RegistrationContainer;
