import React,  { useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro'; 
import MainTimerComponent from './MainTimerComponent';
import StickyTimerComponent from './StickyTimerComponent';
import configs from '../../configuration/configuration.json';

const timer = process.env.REACT_APP_env_svg_base_path + 'time.svg';
const imgBasePath = process.env.REACT_APP_env_img_base_path;

const TimerStyle = styled.div`
    display: flex;
    flex-direction: column;     
    position: relative; 
    border-radius: 4px;
`;
const MainTimerWrapper = styled.div`
    width: 100%;    
`;
const StickyTimerWrapper = styled.div`
    width: 100%;
    position: fixed;
    left: 0;
    top: -100px;
`;

const TimerContainerComponent = (props)=>{
    const dispatch = useDispatch();
    const sectionTimer = useSelector((state) => state.sectionTimer);
    const selectedSection = useSelector((state) => state.selectedSection); 
    const globalState = useSelector((state) => state.global);
    const mainTimer = useRef();
    const stickyTimer = useRef();

    const customizedWith = props.width ? props.width : '';
    const customizedHeight = props.height?props.height: '';
    const timerSize = {width: customizedWith, height: customizedHeight};  
    let timerCustomization = props.customization ? props.customization : null;

    if(timerCustomization){
       timerCustomization = {...timerCustomization, ...timerSize};
    }

    useEffect(() => {
      setTimeout(() => {
        if(sectionTimer && sectionTimer.started){
          dispatch({ type: 'UPDATE_TIMER' });
        }

      }, 1000); 

      window.addEventListener("scroll", scrollHandler, true);
      return () => {
      window.removeEventListener("scroll", scrollHandler, true);
      };
    }); 

    useEffect(() => {
      const timeLeft = sectionTimer.timeLeft.minutes * 60  + sectionTimer.timeLeft.seconds;       
      if (sectionTimer.started && timeLeft === 0 && props.onNext){                    
          props.onNext();
      }
    }, [sectionTimer, props])

    const getElementDistanceToTop = (element) => {
        if(!(element instanceof HTMLElement)){
          return false; 
        } 
        const rect = element.getBoundingClientRect();
        
        return rect.top;
    };
    
    const scrollHandler = () => { 
       
        const headerDistanceFromTop = mainTimer.current ? getElementDistanceToTop(mainTimer.current) : 0;
        const stickyTimerEle = stickyTimer.current;   
        if(headerDistanceFromTop > -15)
        {                    
            if(stickyTimerEle) {
              stickyTimerEle.style.top = "-100px";
            } 
        }
        else if(headerDistanceFromTop > -100){
            const dynamicPosition = headerDistanceFromTop * (-1) - 100 + 'px'
            if(stickyTimerEle){
              stickyTimerEle.style.top = dynamicPosition;
            } 
        }
        else{            
            if(stickyTimerEle){
              stickyTimerEle.style.top = 0;
            } 
        }
    };
  
 if(selectedSection && selectedSection.isTimed && sectionTimer){  
      if(!sectionTimer.started){
        return <div></div>
      }
      return (
        <TimerStyle className={props.className}>           
             <StickyTimerWrapper  id ={'sticky_timer'} ref = {stickyTimer}>
             <StickyTimerComponent
               logoImageUrl={ imgBasePath+globalState.config.logo}        
               timerImg={timer}
               sectionTimer={sectionTimer}               
             />  
             </StickyTimerWrapper> 
             <MainTimerWrapper id={'main_timer'} ref = {mainTimer}>
              <MainTimerComponent              
               timerImg={timer}
               sectionTimer={sectionTimer}              
              />
             </MainTimerWrapper>                                       
      </TimerStyle>     
    );
   }else{
     return <div></div>
   }
}

export default TimerContainerComponent;