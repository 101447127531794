import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import ProgressBarComponent from '../ProgressBarComponent/ProgressBarComponent';
import SecondaryButtonComponent from '../SecondaryButtonComponent/SecondaryButtonComponent';
import { ThemeContext } from 'styled-components';
import { useDispatch } from 'react-redux';
import { up } from 'styled-breakpoints';
import { getClientBaseUrl } from '../../util/helper/Helper';

const StyledRoot = styled.div`
    display: flex;
    justify-content: center;
    height: 3.5em;
    width: 100%;
    box-shadow: 1px 8px 35px ${ props => props.theme.formShadow };
    background-color: ${ props => props.theme.main };
`;

const StyledLogo = styled.img`
    width: 107px;
`;

const VerticalCenteringDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledLoginLText = styled.div`
    color: white;
    padding-right: .5em;
`;

const StyledProgressBarComponent = styled(ProgressBarComponent)`
    width: 40%;
`;

const StyledContainer = styled.div`
    display: flex;
    ${up('md')} {
        width: 70%;
        max-width: 1300px;
        justify-content: space-between;
    }
`;

const Row = styled.div`
    display: flex;
`;

const StyledLoginLink = styled.a`
    font-weight: bold;
    color: white;
    text-decoration: none;
`;

const Username = styled.div`
    color: white;
    font-weight: 500;
    padding-right: 1em;
`;

const DesktopHeaderComponent = (props) => {
    const themeContext = useContext(ThemeContext);
    const dispatch = useDispatch();

    const [username, setUsername] = useState(props.username);
    
    let redirectTo = getClientBaseUrl()

    useEffect(() => {
        setUsername(props.username);
    }, [props.username])

    return(
        <StyledRoot>
            <StyledContainer>
                <VerticalCenteringDiv>
                    <StyledLogo src={props.logoImageUrl}/>
                </VerticalCenteringDiv>
                <VerticalCenteringDiv>
                    <StyledProgressBarComponent bannerImageUrl={props.bannerImageUrl ? props.bannerImageUrl : null}></StyledProgressBarComponent>
                </VerticalCenteringDiv>
                {
                props.showLogin && 
                <VerticalCenteringDiv>
                    <Row>
                        <VerticalCenteringDiv>
                            <StyledLoginLText>
                                Already registered?
                            </StyledLoginLText>
                        </VerticalCenteringDiv>
                        <VerticalCenteringDiv>
                            <StyledLoginLink href={process.env.REACT_APP_env_sso_redirect_url+'?redirectTo='+redirectTo}>
                                <SecondaryButtonComponent size={'small'} color={themeContext.headerText} backgroundColor={themeContext.main} gradientBorder={true} onClick={() => {}} text={'Login'}></SecondaryButtonComponent>
                            </StyledLoginLink>
                        </VerticalCenteringDiv>
                    </Row>
                </VerticalCenteringDiv>
                }
                {
                    props.showLogout &&
                    <VerticalCenteringDiv>
                    <Row>
                        <VerticalCenteringDiv>
                            <Username>{username}</Username>
                        </VerticalCenteringDiv>
                        <VerticalCenteringDiv>
                            <StyledLoginLink onClick={() => { dispatch({type: 'LOG_OUT'}) }}>
                                <SecondaryButtonComponent size={'small'} color={themeContext.headerText} backgroundColor={themeContext.main} gradientBorder={true} onClick={() => {}} text={'Logout'}></SecondaryButtonComponent>
                            </StyledLoginLink>
                        </VerticalCenteringDiv>
                    </Row>
                </VerticalCenteringDiv>
                }
            </StyledContainer>
        </StyledRoot>
    )
}

export default DesktopHeaderComponent;