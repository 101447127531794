import React, {useContext} from 'react';
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import { up } from 'styled-breakpoints';

const StyledRoot = styled.button`
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 9999px !important;
    width: 115px;;
    height: 35px;
    ${up('sm')}{
        width: 145px;
    }
    font-weight: bold;
    text-transform: unset !important;
    font-size: .9em;
    cursor: pointer;
    border: none;
    letter-spacing: .01em;
    color: ${props => props.theme.accentText};
`;

const FancyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100em;
    cursor: pointer;
    width: 123px;
    height: 41px;
    ${up('sm')}{
        width: 151px;
    }
    background: transparent linear-gradient(90deg, ${props => props.theme.secondary} 0%, ${props => props.theme.tertiary} 100%) 0% 0% no-repeat padding-box;
    position: relative;
    z-index: 1;
    &:before{
        content: "";
        border-radius: 100em;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent linear-gradient(90deg, ${props => props.theme.tertiary} 0%, ${props => props.theme.secondary} 100%) 0% 0% no-repeat padding-box;
        opacity: 0;
        transition: opacity 1s;
        z-index: -1;
    }
    &:hover::before{
        opacity: 1;
    }
`;

const PlainWrapper = styled(StyledRoot)`
    box-shadow: 0px 8px 20px #0000000F;
`;

const SmallButton = styled.button`
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 9999px !important;
    width: 96px;
    font-weight: bold;
    text-transform: unset !important;
    height: 23px;
    font-size: .9em;
    z-index: 100;
    cursor: pointer;
    border: none;
    letter-spacing: .01em;
    color: ${props => props.theme.accentText};
`;

const SmallPlainWrapper = styled(SmallButton)`
    box-shadow: 0px 8px 20px #0000000F;
`;

const SmallFancyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100em;
    cursor: pointer;
    width: 100px;
    height: 27px;
    background: transparent linear-gradient(90deg, ${props => props.theme.secondary} 0%, ${props => props.theme.tertiary} 100%) 0% 0% no-repeat padding-box;
    position: relative;
    z-index: 1;
    &:before{
        content: "";
        border-radius: 100em;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent linear-gradient(90deg, ${props => props.theme.tertiary} 0%, ${props => props.theme.secondary} 100%) 0% 0% no-repeat padding-box;
        opacity: 0;
        transition: opacity 1s;
        z-index: -1;
    }
    &:hover::before{
        opacity: 1;
    }
`;

export const SecondaryButtonComponent = (props) => {
    const themeContext = useContext(ThemeContext);
    switch(props.size){
        case "small":
            if(props.gradientBorder){
                return (
                    <SmallFancyWrapper >
                        <SmallButton id={props.id} disabled={ props.disabled } onClick={ props.onClick } style={{ color: props.color || themeContext.accentText, backgroundColor: props.backgroundColor || themeContext.card }}>
                            { props.text }
                        </SmallButton>
                    </SmallFancyWrapper>
                );
            }else{
                return(
                    <SmallPlainWrapper id={props.id} disabled={ props.disabled } onClick={ props.onClick } style={{ color: props.color || themeContext.accentText, backgroundColor: props.backgroundColor || themeContext.card }}>
                            { props.text }
                    </SmallPlainWrapper>
                );
            }
        default:
            if(props.gradientBorder){
                return (
                    <FancyWrapper >
                        <StyledRoot id={props.id} disabled={ props.disabled } onClick={ props.onClick } style={{ color: props.color || themeContext.accentText, backgroundColor: props.backgroundColor || themeContext.card }}>
                            { props.text }
                        </StyledRoot>
                    </FancyWrapper>
                );
            }else{
                return(
                    <PlainWrapper id={props.id} disabled={ props.disabled } onClick={ props.onClick } style={{ color: props.color || themeContext.accentText, backgroundColor: props.backgroundColor || themeContext.card }}>
                            { props.text }
                    </PlainWrapper>
                );
            }
    }
};

export default SecondaryButtonComponent;