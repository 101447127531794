import React from 'react';
import styled from 'styled-components/macro';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ProgressBarImage = styled.img`
`;

export const ProgressBarComponent = (props) => {

  return (
    <StyledRoot>
        {props.bannerImageUrl ? <ProgressBarImage src={props.bannerImageUrl}></ProgressBarImage> : <></>}
    </StyledRoot>
  );
};

export default ProgressBarComponent;