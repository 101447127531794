import 'react-app-polyfill/ie11';
import React from 'react';
import 'babel-polyfill';
import AppComponent from './components/AppComponent/AppComponent';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { render } from 'react-dom';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_env_ld_client_key,    
    options: {}
  });
  
  render(
    <LDProvider>
      <AppComponent />
    </LDProvider>,
    document.getElementById('root'),
  );  
})();

