import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import MobileHeaderComponent from '../HeaderComponent/MobileHeaderComponent';
import FooterComponent from '../FooterComponent/FooterComponent';
import MobileConfirmationComponent from '../ConfirmationComponent/MobileConfirmationComponent.js';
import DividerComponent from '../DividerComponent/DividerComponent';
import configs from '../../configuration/configuration.json';
import { ThemeContext } from 'styled-components';
import RegistrationPrimaryFormContainer from '../../containers/RegistrationPrimaryFormContainer/RegistrationPrimaryFormContainer';
import RegistrationSecondaryFormContainer from '../../containers/RegistrationSecondaryFormContainer/RegistrationSecondayFormContainer';
import RegistrationOverviewContainer from '../../containers/RegistrationOverviewContainer/RegistrationOverviewContainer';
import { PinDropRounded } from '@material-ui/icons';

const imgBasePath = process.env.REACT_APP_env_img_base_path;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
`;

const HeaderBox = styled.div`
  background-color: ${ props => props.theme.main };
  display: flex;
  flex-direction: column;
  padding-bottom: 2em;
  text-align: center;
  color: white;
  position: relative;
  left: 0;
  z-index: 1;
  padding-top: 1em;
`;

const RegHeaderText = styled.h1`
  margin-bottom: .3em;
`;

const RegBlurbText = styled.p`
  margin-top: 1em;
`;

const RegBlurb = styled.div`
  margin-top: .3em;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
`;

const StyledWrapper = styled.div`
  display: flex;
  background-color: ${props => props.theme.card};
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

const TemplatedHeaderbox = styled.div`
  background: ${props => props.theme.registrationHeaderBackground};
  display: flex;
  flex-direction: column;
  padding-bottom: 2em;
  justify-content: left;
  color: white;
  position: relative;
  left: 0;
  z-index: 1;
  padding-top: 1em;
`;

const PoweredByCatalyteContainer = styled.div`
  width: 100%;
  height: 2em;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
`;

const CreateOpportunityLogoContainer = styled.img`
  width: 50%;
  height: 3em;
  margin-top: 7%;
  margin-left: 1.75em;
`;

const PoweredByText = styled.p`
  width: 6em;
  font-size: 12px;
  margin-left: 2.5em;
`;

const CatalyteLogoContainer = styled.img`
  width: 4em;
  height: 1.25em;
  margin-top: 2.2%;
`;

const MobileRegistrationComponent = (props) =>{
    const themeContext = useContext(ThemeContext);
    const [global, setGlobal] = useState(props.globalState);
    let childProps = {...props, themeContext};

    useEffect(() => {
      setGlobal(props.globalState);
    }, [props.globalState])

    const renderHeaderBox = function(){
      if(props.client === 'createoppcolumbia'){
        return(
          <TemplatedHeaderbox>
            <CreateOpportunityLogoContainer src={imgBasePath + 'create-op-logo.png'} />
            <PoweredByCatalyteContainer>
              <PoweredByText>Powered by</PoweredByText>
              <CatalyteLogoContainer src={imgBasePath + 'catalyte_white_logo_transparent.png'} />
            </PoweredByCatalyteContainer>
          </TemplatedHeaderbox>
        )
      }else{
        return(
          <HeaderBox>
            <RegHeaderText>{headerText}</RegHeaderText>
            <DividerComponent />
            <RegBlurb>
              <RegBlurbText>{blurbText}</RegBlurbText>
            </RegBlurb>
          </HeaderBox>
        )
      }
    }
    
    const renderForm = function(form){
      switch(form){
        case "overview": return(
          <RegistrationOverviewContainer {...childProps} />
        )
        case "primary": return(
          <RegistrationPrimaryFormContainer {...childProps} />
        )
        case "secondary": return(
          <RegistrationSecondaryFormContainer {...childProps} />
        )
        case "confirmation": return(
          <MobileConfirmationComponent {...childProps} />
        )
        default:
          return (
            <RegistrationPrimaryFormContainer {...childProps} />
        )
      }
    }

    const registrationConfig = props.globalState.config && props.globalState.config.registration || {"confirmation": {}, "primary": {}};

    const headerText = props.currentForm === 'confirmation' ? registrationConfig.confirmation.header : registrationConfig.primary.header;
    const blurbText = props.currentForm === 'confirmation' ? registrationConfig.confirmation.blurb : registrationConfig.primary.blurb;
    const imgBasePath = process.env.REACT_APP_env_img_base_path;

    return (
      <StyledRoot>
        <MobileHeaderComponent logoImageUrl={imgBasePath + props.globalState.config.logo} showLogin={!global.cognitoUser} showLogout={global.cognitoUser} username={global.cognitoUser && global.cognitoUser.username}/>
        <StyledWrapper>
          { renderHeaderBox() }
          { renderForm(props.currentForm) }
        </StyledWrapper>
        <FooterComponent/>
      </StyledRoot>
    );
  }

export default MobileRegistrationComponent;
