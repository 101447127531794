import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { TextInputComponent }  from '../TextInputComponent/TextInputComponent';
import AutocompleteComponent from '../DropDownListComponent/AutocompleteComponent';
import { colors } from '../../Themes/Colors';
import { up } from 'styled-breakpoints';

const imgBasePath = process.env.REACT_APP_env_svg_base_path;

const StyledRoot = styled.div`
  display: flex;    
  flex-wrap: wrap;
  justify-content: center;  
`;

const StyledFormLabel = styled.div`
  display: flex;
  width: 100%;  
`;

const StyledPrompt = styled.div`
  width: 100%;  

  img {
    width: 100%;
  }
`;

const FormInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 16em;  
  margin-top: 1.5em;

  > div:last-child{
    width: 60px;
  }

  div:empty{
    display: none;
  }
`;

const StyledTextInputComponent = styled(TextInputComponent)`
  width: 100%;
`;

const StyledAutocompleteComponent = styled(AutocompleteComponent)`
    font-weight: 400;
`;

const getPrompts = (prompts) =>{
    if(!prompts){
      return(<div></div>)
    }
    let orderedPrompts = prompts.sort((a, b) => (a.order > b.order) ? 1 : -1)
    return(
      orderedPrompts.map((prompt, i) =>{
            if(prompt.valueType === 'img'){
            return (<StyledPrompt key={'prompt_'+i} style={{  color: colors.darkGrey }}><img alt={prompt.description} src={imgBasePath+prompt.url} ></img></StyledPrompt>);       
            }else{
            return (<StyledPrompt key={'prompt_'+i} style={{  color: colors.darkGrey }} dangerouslySetInnerHTML={{__html: prompt.value}}></StyledPrompt>);       
            }
        })
    );
}

const getOptions = (question) =>{
  if(!question.options){
    return([])
  }else{
    let orderedOptions = question.options.sort((a, b) => (parseInt(a.order) > parseInt(b.order)) ? 1 : -1);
    return(orderedOptions.map((v, i) => { return v.value }));
  }
}

const getAnswerText = (question) => {
    if(question && question.answer){
        return question.answer.number || "";
    }
    return "";
}

const getDdlAnswer = (question) => {
    if(question && question.answer){
        return question.answer.ddl || "";
    }
    return "";
}

const NumberDdlPairComponent = (props) => {
    const dispatch = useDispatch();

    const [textState, setTextState] = useState(getAnswerText(props.question));
    const [ddlState, setDdlState] = useState(getDdlAnswer(props.question));

    if(!props.question)
      {return (<div></div>);}



    const handleChange = (event) => {
        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": { "number": textState, "ddl": ddlState }}});
    }

    const handleDdlChange = (val) => {
        setDdlState(val);
        handleChange();
    }

    const handleTextChange = (val) => {
        setTextState(val);
    }

    return (
      <StyledRoot>
        <StyledFormLabel>{getPrompts(props.question.prompts)}</StyledFormLabel>
        <FormInputsWrapper>
        <StyledAutocompleteComponent 
                      onChange={handleDdlChange}
                      question={props.question}
                      selectionOption = {getOptions(props.question)}
                      value={ddlState}
                      helperText={''}
                      label={""}
                      error={false}
                      isValid={true}
                      validList={getOptions(props.question)}
                      isSubmit={true}
                      width={288}
                      fontWeightValue={400}
                      placeholder={'Select Color'} 
                      dropDownHeight={420}                          
                      required={false}                       
                    />
          <StyledTextInputComponent backgroudColor="#cbcbcb" type='number' onChange={handleTextChange} onBlur={handleChange} value={textState} />
        </FormInputsWrapper>
      </StyledRoot>
    );
}

export default NumberDdlPairComponent;