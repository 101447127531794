import React from 'react';
import { Redirect } from 'react-router-dom';
import { PrimaryButtonComponent } from '../PrimaryButtonComponent/PrimaryButtonComponent';
import { TextInputComponent } from '../TextInputComponent/TextInputComponent';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';

const StyledConfirm = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10% 0% 5% 0%;
	background-color: ${props => props.theme.card};
	z-index: 1;
    flex-grow: 1;
`;

const StyledInput = styled.div`
	display: flex;
    justify-content: center;
}
`;

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 360px;
`;

const MainText = styled.div`
	color: ${props => props.theme.primaryText};
	justify-content: center;
	display: flex;
	line-height: 2em;
`;

const BoldText = styled(MainText)`
	font-weight: 500;
`;

const TextInput = styled(TextInputComponent)`
	margin-top: 1em;
`;

const UserEmailText = styled(BoldText)`
	padding-left: .2em;
`;

const ResendLink = styled.div`
	display: flex;
	justify-content: center;
	font-weight: 500;
	cursor: pointer;
	color: ${props => props.theme.accentText};
`;

const StyledButton = styled(PrimaryButtonComponent)`
	margin-bottom: 1em;
`;

const MainTextClickedContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const CheckmarkIcon = styled.div`
	background-image: url(./images/check.svg);
	background-size: cover;
	width: 1.05em;
	height: 1em;
`;

const ClickedMainText = styled.div`
	color: ${props => props.theme.accentText};
	justify-content: center;
	text-align: center;
	display: flex;
	width: 6em
	line-height: 2em;
	font-weight: 500;
`;

const ClickedResendLink = styled.div`
	display: flex;
	justify-content: center;
	max-width: 19em;
	text-align: center;
	font-weight: 400;
	cursor: pointer;
	color: ${props => props.theme.primaryText};
`;

const MobileConfirmationComponent = (props) => {
	const dispatch = useDispatch();
	const [code, setCode] = React.useState('');
	const [resendClicked, setResendClicked] = React.useState(false);
	
	const handleOnChange = (v) => {
		if(v.match('^[0-9]*$')){
			setCode(v);
		}
	}

	const handleButtonClick = (e) => {
		e.preventDefault();
		if(process.env.REACT_APP_env_cookie_domain === 'catalyte.io'){
			const script = document.getElementById("g-recaptcha-script");
			if(script){
				document.body.removeChild(script);
			}
			const badgeEle = document.querySelectorAll('.grecaptcha-badge');
			if(badgeEle && badgeEle[0]){
				badgeEle[0].remove();
			}
        }
        props.onClickSubmit({cc: code});
	}

	const handleResendClick = (e) => {
		setResendClicked(true);
		setTimeout(() => {
			setResendClicked(false);
		}, 10000);
		const userEmailString = props.email;
		const usernameString = userEmailString.substring(0, userEmailString.lastIndexOf("@"));
		dispatch({ 'type': 'COGNITO_USER_REVERIFY', data: {username: usernameString} });
	}

	const boxShadow = props.themeContext.buttonShadow;

	if(props.confirmed === true){
		return (
			<Redirect to='/welcome'/>
		)
	}else{
		return (
			<StyledConfirm>
				<StyledWrapper>
					<MainText><BoldText>We sent a code to verify your email address</BoldText></MainText>
					<MainText>Sent to<UserEmailText>{props.email}</UserEmailText></MainText>
					<StyledInput>
						<TextInput id={'cc-input'} fontWeight={'600'} color={props.themeContext.accentText} textAlign={'center'} boxShadow={boxShadow} noLabelPlaceholder={true} placeholder={''} helperText={props.confirmationFailed ? 'Validation failed' : ''} value={code} error={props.confirmationFailed} onChange={handleOnChange} />
					</StyledInput>
					<StyledInput>
						<StyledButton id={'submit-btn'} text={'Submit'} onClick={handleButtonClick} />
					</StyledInput>
					{resendClicked ? <MainTextClickedContainer><CheckmarkIcon /><ClickedMainText>Code resent</ClickedMainText></MainTextClickedContainer> : <MainText>Didn't receive a code?</MainText>}
					{resendClicked ? <ClickedResendLink>Please check your email again including your Junk folder for the most recent code sent.</ClickedResendLink> : <ResendLink onClick={handleResendClick}>Resend</ResendLink>}
				</StyledWrapper>
			</StyledConfirm>
		)
	}
}

export default MobileConfirmationComponent;