const selectedSectionReducer = (state = null, action ) => {

switch(action.type){
        case "SET_SECTION":
            action.data.rendering = true;            
            return action.data;
        case "UPDATE_SECTION_RENDERING": {
            let renderedSection = JSON.parse(JSON.stringify(state));          
            renderedSection.rendering = false;
            if(isMissingRequired(renderedSection)){
                renderedSection.missingRequired = true;
            }else{
                renderedSection.missingRequired = false;
            }
            return renderedSection;
        }
        case "UPDATE_SECTION_QUESTION": {

            let updatedSection = JSON.parse(JSON.stringify(state));
            
            updatedSection.answers = updatedSection.answers.map((v, i) => {
                if(v.questionID === action.data.id){
                    v.input = (typeof(action.data.answer) === "object" && action.data.answer !== null) ? JSON.stringify(action.data.answer) : action.data.answer;
                }
                return v;
            });

            updatedSection.missingRequired = isMissingRequired(updatedSection);

            updatedSection.questions = updatedSection.questions.map((v, i) => {
                if(v.uuid === action.data.uuid){
                    v.answer = action.data.answer;
                    v.isVisible = action.data.isVisible ?? v.isVisible;
                    v.groupOrder = action.data.groupOrder;
                }
                return v;
            });
            return updatedSection;
        }
        case "UPDATE_SAVED_SECTION": {

            let newSectionData = JSON.parse(JSON.stringify(action.data));

            newSectionData.missingRequired = isMissingRequired(newSectionData);
            
            newSectionData.questions = newSectionData.questions.sort((a, b) => (a.order > b.order) ? 1 : -1);

            newSectionData.questions = newSectionData.questions.map((question, i) => { 
                   
                    if(question.type === 'checkbox.text' || question.type === 'checkbox' || 
                       question.type === 'number.ddl' || question.type.toLowerCase().indexOf('ddl.multipleselect') > -1){
                            question.answer = question.options.map((option, i) => {
                                    option.input = '';
                                    return option;
                            })
                            return question;
                    }
                    return question;
            });

            newSectionData.questions = newSectionData.questions.map((question, i) => {
                    var answer = newSectionData.answers.filter((answer, i) => answer.questionID === question.questionID)[0].input;                                
                  
                    if(!answer){
                        return question;
                    }
                  
                    if(question.type === 'checkbox.text' || question.type === 'checkbox' || newSectionData.type === 'multiForm.employment' ||
                       question.type === 'number.ddl' || question.type.toLowerCase().indexOf('ddl.multipleselect') > -1){                                          
                            question.answer = JSON.parse(answer);
                    }else{
                            question.answer = answer;
                    }
                    return question;
            });

            return newSectionData;
        }
        default:
                return state;
    }
};

const isMissingRequired = (section) => {
    if(!section || !section.questions){
      return false;
    }
    for(let q of section.questions){
        if(q.required){
            for(let a of section.answers){
                if(a.questionID === q.questionID && !a.input){
                    return true;
                }
            }
        }
    }
    return false;
  }

export default selectedSectionReducer;