import { fromEvent } from 'rxjs';
import apiCall from './axiosAPIRequest/axiosAPIRequest';
import axios from 'axios';

const metaUrl = process.env.REACT_APP_env_meta_url;

const getUserId = () => {
    const lsid = localStorage.getItem('ap_i');

    if(!lsid){
        return null;
    }else{
        return lsid;
    }
}

export const log = function () {

try{
        const sendKeystroke = async function (data) {
            if(!metaUrl || window.location.pathname  === '/registration' || window.location.pathname === '/error'){
                return
            }

            let nav = { userId: getUserId(), action: 'navigator' };

            for(let k in window.navigator){ 
                nav[k] = window.navigator[k];
            }

            let parsed = JSON.parse(data.body);
            parsed["nav"] = JSON.stringify(nav);
            data.body = parsed;

            await apiCall(metaUrl, 'POST', data)
                .then((res) => {
                    localStorage.setItem('ks', '[]')
                })
                .catch((err) => {
                    console.log(err)
                });
        }

        const parseKeyup = function (rawData) {
            return {
                userId: getUserId(),
                action: 'keyup',
                target: rawData.target.id,
                keyCode: rawData.keyCode,
                timestamp: new Date().getTime()
            };
        };

        const parseClick = function (rawData) {
            return {
                userId: getUserId(),
                action: 'click',
                target: rawData.target.id,
                keyCode: rawData.keyCode,
                timestamp: new Date().getTime()
            };
        };

        const parseFocus = function (rawData) {
            return {
                userId: getUserId(),
                action: 'focus',
                target: rawData.target.id,
                keyCode: rawData.keyCode,
                timestamp: new Date().getTime()
            };
        };

        const parseBlur = function (rawData) {
            return {
                userId: getUserId(),
                action: 'blur',
                target: rawData.target.id,
                keyCode: rawData.keyCode,
                timestamp: new Date().getTime()
            };
        };

        const parseSelect = function (rawData) {
            return {
                userId: getUserId(),
                action: 'select',
                target: rawData.target.id,
                keyCode: rawData.keyCode,
                timestamp: new Date().getTime()
            };
        };

        const appendData = function (data) {

            let currentStorage = localStorage.getItem('ks');

            if(!currentStorage){
                currentStorage = '[]';
            }

            if (!currentStorage) {
                const newStorage = [];
                newStorage.push(data);
                localStorage.setItem('ks', JSON.stringify(newStorage));
            }
            else{
                const current = JSON.parse(currentStorage);
                current.push(data);
                localStorage.setItem('ks', JSON.stringify(current));
            }
        };

        const keyObserver = fromEvent(document, 'keyup');
        const keySelect = fromEvent(document, 'select');
        const clickObserver = fromEvent(document, 'click');
        const focusObserver = fromEvent(document, 'focus');
        const blurObserver = fromEvent(document, 'blur');

        const sendLoop = () => {
            setTimeout(function(){
                const rawKs = localStorage.getItem('ks')
                if(rawKs && JSON.parse(rawKs).length > 0){
                    const data = { body: rawKs }
                    sendKeystroke(data)
                }
                sendLoop()
            }, 5000)
        }

        sendLoop();

        keyObserver
            .subscribe((v) =>
                appendData(parseKeyup(v)
                )
            );

        clickObserver
            .subscribe((v) =>
                appendData(parseClick(v)
                )
            );

        focusObserver
            .subscribe((v) =>
                appendData(parseFocus(v)
                )
            );

        blurObserver
            .subscribe((v) =>
                appendData(parseBlur(v)
                )
            );

        keySelect
        .subscribe((v) =>
                appendData(parseSelect(v)
            )
        );

    }catch(err){
        console.log(err)
    }
};

