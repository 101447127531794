import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { up } from 'styled-breakpoints';

const StyledRoot = styled.div`
    height: 100%;
    width: 100%;
    display: flex;    
    background-color: ${ props => props.theme.main };
    align-items: center;
    ${(up('lg'))}{
        height: 7em;     
        align-items: flex-start;    
        justify-content: flex-start;                               
    }   
`;

const LogoWrapper = styled.div`   
    margin: 0 2em;     
    ${(up('lg'))}{
        margin: 1em auto 0;    
        width: 70%;
        max-width: 1300px;    
    }     
`;

const StyledLogo = styled.img`
    width: 107px;
   
    ${(up('lg'))}{
        width: 107px;
        height: 30px;
    }
`;
const FooterComponent = () => {
    const globalState = useSelector((state) => state.global);
    const imgBasePath = process.env.REACT_APP_env_img_base_path;
    return(
        <StyledRoot>
            <LogoWrapper>
                <StyledLogo src={imgBasePath + globalState.config.logo}/>
            </LogoWrapper>
        </StyledRoot>
    )
}

export default FooterComponent;