import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import SSOProvider from '../SSOProvider/SSOProvider';
import WrapperComponent from '../WrapperComponent/WrapperComponent';
import RegistrationContainer from '../../containers/RegistrationContainer/RegistrationContainer';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPageComponent from '../ErrorPageComponent/ErrorPageComponent'; 
import ThankyouComponent from '../ThankYouComponent/ThankYouComponent';
import WelcomePageComponent from '../WelcomePageComponent/WelcomePageComponent';
import Amplify from 'aws-amplify';
import store from '../../redux/store';
import {log} from '../../util/keystroke';
import { createBrowserHistory } from 'history';
import { getClientBaseUrl } from '../../util/helper/Helper';

log();

const history = createBrowserHistory();

let ssoAuthConfig;

if (process.env.REACT_APP_env_use_sso === 'true') {
  ssoAuthConfig = {
    identityPoolId: process.env.REACT_APP_env_indentitypool_id,
    region: process.env.REACT_APP_env_cognito_region,
    userPoolId: process.env.REACT_APP_env_userpool_id,
    userPoolWebClientId: process.env.REACT_APP_env_userpool_client_id,
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: process.env.REACT_APP_env_cookie_domain,
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: process.env.REACT_APP_env_cookie_secure === 'true'
    }
  };
}

Amplify.configure({
  Auth: ssoAuthConfig
});

let appUrl = getClientBaseUrl()

const AppComponent = () => (
  
  <Provider store={store}>
        <Router history={history} >
          <Switch>
            <Route exact path="/"
                  render={() => {
                        return (
                          <Redirect to="/home" />
                        )
                    }}/>
          
            <Route exact path="/registration" component={RegistrationContainer} />
            <Route exact path="/createoppcolumbia" component={RegistrationContainer} />
            <Route path="/error" component={ErrorPageComponent} />
            <SSOProvider
              authConfig={ssoAuthConfig}
              appName="screen"
              cancelLogoutTimeout={600000}
              inactivityTimeout={6000000}
              redirectUrl={`${appUrl}registration`}
              >
              <ErrorBoundary FallbackComponent={ErrorPageComponent} >
                <Route path="/thankyou" component={ThankyouComponent} />
                <Route path="/welcome" component={WelcomePageComponent} />
                <Route path="/home" component={WrapperComponent} />
              </ErrorBoundary>            
            </SSOProvider>
          </Switch>
        </Router>
  </Provider>
);

export default AppComponent;
