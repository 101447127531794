import React, { useState, useEffect } from 'react';
import PrimaryFormComponent from '../../components/RegistrationComponent/PrimaryFormComponent';
import configs from '../../configuration/configuration.json';
import MobilePrimaryFormComponent from '../../components/RegistrationComponent/MobilePrimaryFormComponent';

const stateList = configs['default'].states;

const RegistrationPrimaryFormContainer = (props) =>{

    let primaryFormProps = {...props};

    //** Validators **//
    const validateEmail = primaryFormProps.validateEmail = (email) => {
        let validEmail = !!email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        return validEmail;
    };
  
    const validateFirstName = primaryFormProps.validateFirstName = (val) => {
        let validFirstName = val.length >= 1 && val.length <= 255 && val.trim() !== '';
        return validFirstName;
    };

    const validateLastName = primaryFormProps.validateLastName = (val) => {
      let validLastName = val.length >= 1 && val.length <= 255  && val.trim() !== '';
      return validLastName;
    };
  
    const validateZipcode = primaryFormProps.validateZipCode = (zipcode) => {
        let validZip = /^\d{5}$|^\d{5}-\d{4}$/.test(zipcode);
        return validZip;
    };

    const validateState = primaryFormProps.validateState = (v) => {
        let validState = !!(v && stateList[v]);
        return validState;
    }

    const validateAddress = primaryFormProps.validateAddress = (val) => {
      let validAddress = (val == null ? false : val.length >= 2);
      return validAddress;
    }

    const validateCity = primaryFormProps.validateCity = (val) => {
      let validCity = val == null ? false : val.length >= 2
      return validCity;
    }

    const validatePhoneNumber = primaryFormProps.validatePhoneNumber = (val) => {
      let cleanInput = val ? val.replaceAll('-','') : "";
      return /^\d{10}$/.test(cleanInput);
    }

    const validateNumber = (input) => {
    
      if(input.length < 4){ 
        return /^\d+$/.test(input);
      }
      else if(input.length < 8){  
        return /^\d{3}-\d*$/.test(input);
      }
      else  if(input.length > 7){ 
        return /^\d{3}-\d{3}-\d{4}$/.test(input);
      }
      else{
        return false;
      }
    }

    const getIsValid = primaryFormProps.getIsValid = () =>{
      const fnameValid = validateFirstName(firstName);
      const lnameValid = validateLastName(lastName);
      const zipValid = validateZipcode(zipcode);
      const emailValid = validateEmail(email);
      const stateValid = validateState(state);
      const addressValid = validateAddress(address);
      const cityValid = validateCity(city);
      const phoneIsValid = validatePhoneNumber(phone);

      return fnameValid && lnameValid && zipValid && emailValid && stateValid && phoneIsValid && addressValid && cityValid;
    }

    //** Field values **//
    const [firstName, setFname] = useState(props.firstName);
    const [lastName, setLname] = useState(props.lastName);
    const [email, setEmail] = useState(props.email);
    const [zipcode, setZipcode] = useState(props.zipcode);
    const [address, setAddress] = useState(props.address);
    const [apt, setApt] = useState(props.apt);
    const [city, setCity] = useState(props.city);
    const [state, setState] = useState(props.state);
    const [phone, setPhone] = useState(props.phone);    

    //** Field validation **//
    const [isValidFname, setValidFname] = useState(props.firstName ? validateFirstName(props.firstName) : null);
    const [isValidLname, setValidLname] = useState(props.lastName ? validateLastName(props.lastName) : null);
    const [isValidEmail, setValidEmail] = useState(props.email ? validateEmail(props.email) : null);
    const [isValidZip, setValidZip] = useState(props.zipcode ? validateZipcode(props.zipcode) : null);
    const [isValidState, setValidState] = useState(props.state ? validateState(props.state) : null);  
    const [isValidAddress, setValidAddress] = useState(props.address ? validateAddress(props.address) : null);
    const [isValidCity, setValidCity] = useState(props.city ? validateCity(props.city) : null);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(props.phone ? validatePhoneNumber(props.phone) : null);
    const [isValid, setIsValid] = useState(null);

    primaryFormProps.firstName = firstName;
    primaryFormProps.lastName = lastName;
    primaryFormProps.email = email;
    primaryFormProps.zipcode = zipcode;
    primaryFormProps.address = address;
    primaryFormProps.apt = apt;
    primaryFormProps.city = city;
    primaryFormProps.state = state;
    primaryFormProps.phone = phone;
    primaryFormProps.isValidFname = isValidFname;
    primaryFormProps.isValidLname = isValidLname;
    primaryFormProps.isValidEmail = isValidEmail;
    primaryFormProps.isValidZip = isValidZip;
    primaryFormProps.isValidAddress = isValidAddress;
    primaryFormProps.isValidCity = isValidCity;
    primaryFormProps.isValidState = isValidState;
    primaryFormProps.isValidPhone = isValidPhoneNumber;
    primaryFormProps.isValid = isValid;

    //** Change Handlers **//    
    primaryFormProps.onAptChange = (v) => {
      setApt(v);
    }

    primaryFormProps.onCityChange = (v) => {
      setCity(v);
    }

    primaryFormProps.onStateChange = (v) => {
      v = v.toUpperCase();
      if(/^[a-zA-Z]*$/.test(v)){
        setState(v.length > 2? v.slice(0,2):v);
      } 
    }

    primaryFormProps.onAddressChange = (v) => {
      setAddress(v);
    }
  
    primaryFormProps.onFnameChange = (v) => {
      if(primaryFormProps.globalState.cognitoUser && primaryFormProps.globalState.cognitoUser.given_name) {
        return
      }
      setFname(v);
    };

    primaryFormProps.onZipcodeChange = (v) => {
        setZipcode(v);
      };
  
    primaryFormProps.onLnameChange = (v) => {
      if(primaryFormProps.globalState.cognitoUser && primaryFormProps.globalState.cognitoUser.family_name) {
        return
      }
      setLname(v);
    };
  
    primaryFormProps.onEmailChange = (v) => {
      if(primaryFormProps.globalState.cognitoUser) {
        return
      }
      setEmail(v);
    };

    primaryFormProps.onPhoneChange = (v) => {
      if(!validateNumber(v)){
         
        let cleanedInput = v.replace(/\D/g,'');

        if(cleanedInput.length > 3)
        {
          cleanedInput = cleanedInput.replace(/.{3}/,'$&-');                 
        }
        if(cleanedInput.length > 7 && cleanedInput.length <13)
        {  
          cleanedInput = cleanedInput.replace(/.{7}/,'$&-'); 
        }           
        
          setPhone(cleanedInput.slice(0,12));
        }
        else{
          setPhone(v);
        }     
    }

    //** Key Handlers **//
    primaryFormProps.handleOnKeyDown = (e) => {
      if(isValid && (e.key === "Enter")){
        primaryFormProps.handleSubmit(e);
      }
    }

    //** Click Handlers **//
    primaryFormProps.handleSubmit = (e) => {
      e.preventDefault();
      if(isValid){        
        props.onClickSubmit({ firstName, lastName, email, address, phone, apt, city, state, zipcode });
      }
    }

    //** Blur Handlers **//
    primaryFormProps.onLnameBlur = (v) => {     
        setValidLname(validateLastName(v));
      }

    primaryFormProps.onFnameBlur = (v) => {
        setValidFname(validateFirstName(v));
      }

    primaryFormProps.onEmailBlur = (v) => {
      setValidEmail(validateEmail(v));
    }

    primaryFormProps.onAddressBlur = (v) => {
      setValidAddress(validateAddress(v));
    }

    primaryFormProps.onCityBlur = (v) => {
      setValidCity(validateCity(v));
    }

    primaryFormProps.onStateBlur = (v) => {

      setValidState(validateState(v));
    }

    primaryFormProps.onZipCodeBlur = (v) => {
      setValidZip(validateZipcode(v));
    }

    primaryFormProps.onPhoneBlur = (v) => { 
      const IsInputValid = validatePhoneNumber(v); 
      setIsValidPhoneNumber(IsInputValid);
   
      if(IsInputValid){           
          setPhone(v.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));                       
      }  
    }

    const handleOnBlur = (input) => { 
      const IsInputValid = validatePhoneNumber(input); 
      setIsValidPhoneNumber(IsInputValid);
   
      if(IsInputValid){           
          setPhone(input.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));                       
      }  
      props.onPhoneBlur(IsInputValid);             
    }
    
    primaryFormProps.getEmailHelper = () => {
      if(!isValidEmail){
        return 'Please enter a valid email address';
      }
      if(props.userExists === true){
        return 'This email has already been used';
      }
      return '';
    }

    useEffect(() => {
      setFname(props.firstName);
      if(props.firstName){
        setValidFname(props.firstName);
      }
    }, [props.firstName])

    useEffect(() => {
      setLname(props.lastName);
      if(props.lastName){
        setValidLname(props.lastName);
      }
    }, [props.lastName])

    useEffect(() => {
      setPhone(props.phone);
      if(props.phone){
        setIsValidPhoneNumber(props.phone);
      }
    }, [props.phone])

    useEffect(() => {
      setState(props.state);
      if(props.state){
        setValidState(props.state);
      }
    }, [props.state])

    useEffect(() => {
      setAddress(props.address);
      if(props.address){
        setValidAddress(props.address);
      }
    }, [props.address])

    useEffect(() => {
      setCity(props.city);
      if(props.city){
        setValidCity(props.city);
      }
    }, [props.city])

    useEffect(() => {
      setEmail(props.email);
      if(props.email){
        setValidEmail(props.email);
      }
    }, [props.email])

    useEffect(() => {
      setIsValid(getIsValid());
    }, [firstName, lastName, email, address, phone, apt, city, state, zipcode])

    useEffect(() => {
      scrollToTop();
    }, [])

    const scrollToTop = () => {
      window.scrollTo({
        top: 0
      });
    }

    
    if(props.isSmallScreen){
      return (
        <MobilePrimaryFormComponent {...primaryFormProps}></MobilePrimaryFormComponent>
      )
    }else{
      return(
        <PrimaryFormComponent {...primaryFormProps}></PrimaryFormComponent>
      )
    }
  }

export default RegistrationPrimaryFormContainer;
