import React from 'react';
import styled from 'styled-components/macro'; 
import SectionParentComponent from '../SectionParentComponent/SectionParentComponent';
import DividerComponent from '../DividerComponent/DividerComponent';
import SectionBorderComponent from './SectionBorderComponent';
import SectionTransitionComponent from '../SectionTransitionComponent/SectionTransitionComponent';
import { useMediaQuery } from 'react-responsive';
import PulseSpinnerComponent from '../PulseSpinnerComponent/PulseSpinnerComponent';
import { up } from 'styled-breakpoints';

const StyledRoot = styled.div`
  background-color: ${props => props.theme.background}
  align-content: center;   
  display: flex;
  flex-direction: column;
  flex-grow: 1;
 
`;

const StyledTop = styled.div`
  background-color: ${ props => props.theme.main }; 
`;

const StyledTitle = styled.div`
  padding-top: 1em;  
  margin-bottom: 1em;
  background-color: ${ props => props.theme.main };
`;

const StyledTitleText = styled.div`     
  font-weight: bold;
  font-size: 2em;
  color: #FFFFFF;
  color: ${props => props.theme.headerText};
`;

const PrimaryTextWrapper = styled.div`  
  max-width: 700px;
  text-align: left;   
  letter-spacing: 0px;
  opacity: 1;  
  color: ${props => props.theme.headerText};
  font-weight: 600;
  background-color: ${ props => props.theme.main };
`;

const StyledInstruction = styled.div`  
  color: ${props => props.theme.headerText};
  font-weight: 600;
  background-color: ${ props => props.theme.main };
`;

const StyledSeparateBar = styled.div`
  padding-top: 8px;
  width: 30px;
`;

const StyledQuestionNumber = styled.div`
  padding-top: 17px; 
  font-weight: bold;
  font-size: 1.3em;  
  color: ${props => props.theme.headerText};
`;

const StyledWavyLineContainer = styled.div`
  flex: auto;
  
`;

const StyledSectionParentComponent = styled(SectionParentComponent)`
  color: ${props => props.theme.primaryText}
`;

const StyledTitleInstructionWrapper = styled.div`
  display: flex;
  justify-content: space-between; 
  margin: 0 2em; 
  width: 100%;

  ${up('lg')} {
    margin: 0;
    width: 70%;    
    max-width: 1300px;
  }
`;

const CenteredRow = styled.div`
  display: flex;
  justify-content: center;
`;

const CenteredColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 8%;
`;

const getNumberOfGroups = (questions) =>{
  let numberOfGroups = 0, numberOfNonGroupQuestions = 0;   

  for (let i = 0; i < questions.length; i++){     
      
    if(questions[i].group && parseInt(questions[i].group) > 0){
      // get number of groups            
      if(numberOfGroups < parseInt(questions[i].group)){             
          numberOfGroups = parseInt(questions[i].group);               
      }
    } 
    else if(questions[i].group == null && questions[i].type !== 'noAnswer') {
      //number of questions not in group needs to be included for count
      numberOfNonGroupQuestions ++;           
    }        
    
    if((i>0 && questions[i].type === "noAnswer") || questions[i].class === "text.reading.passage_text"){
      //it needs to exclude the number of questions if the question is instruction text         
      numberOfNonGroupQuestions--;          
    }       
  }

  const numbersOfGroupsAndNonGroupedQuestions = { "numberOfGroups": numberOfGroups, "numberOfNonGroupQuestions": numberOfNonGroupQuestions };
  return numbersOfGroupsAndNonGroupedQuestions;
}

const getInstructionAndNumberOfQuestions = (questions)=> {
  const questionInstAndNumb =[];
  let numberOfQuestions = questions.length;
  const numbersOfQuestionsNGroups = getNumberOfGroups(questions);
  let numberOfGroups = numbersOfQuestionsNGroups.numberOfGroups;    
  let numberOfNonGroupQuestions = numbersOfQuestionsNGroups.numberOfNonGroupQuestions;
  
  //get section instruction
  if(questions[0].type === "noAnswer"){ 
    // first question is instruction            
    questionInstAndNumb.push(questions[0].prompts[0].value);
    //exclude instruction from question counting 
    numberOfQuestions--;          
  }
  
  if(numberOfGroups > 0){
    // it needs to exclude non group questions from grouped questions for grouping
    numberOfQuestions = numberOfQuestions - numberOfNonGroupQuestions ;        
    numberOfQuestions = Math.round(numberOfQuestions / numberOfGroups);        
    // add back excluded question for total count
    numberOfQuestions = numberOfQuestions + numberOfNonGroupQuestions;        
  }    

  if(questionInstAndNumb.length >0){
    // there is instruction
    questionInstAndNumb.push(numberOfQuestions);
  }else{
    //for sections which are not of instruction
    questionInstAndNumb.push('');
    questionInstAndNumb.push(numberOfQuestions);
  }
      
  return questionInstAndNumb
}

const ScreeningContainerComponent = (props) => {
    const selectedSection = props.selectedSection;
    const sections = props.sections;
    const global = props.global;
    const isBigScreen = useMediaQuery({ query: '(min-width: 1160px)' });
    const showTransitionPage = props.showTransitionPage;
    const handleTransitionStart = props.handleTransitionStart;
    const handleTransitionNext = props.handleTransitionNext;
    const handleTransitionBack = props.handleTransitionBack;
     
    let title, qnumb;
   
    if(selectedSection){
      title = selectedSection.name;

      if(selectedSection.questions){
        const instrAndQnumber = getInstructionAndNumberOfQuestions(selectedSection.questions);
        if(instrAndQnumber && instrAndQnumber.length > 1){
          qnumb = instrAndQnumber[1] ;  
        }
      }
    }
    
    const renderContainerComponent = () => {

        if(global.showLoadingSpinner){
          return (                      
              <PulseSpinnerComponent
                dotColorArray={['#F7C634', '#f6bf55', '#f7ac43', '#f79a34']}
                dotSpeedArray={[1.8, 1.8, 1.8, 1.8]}
                dotDelayArray={[0, 0.2, 0.4, 0.6]}
                size={30}
                positionArray={[5, 10]}
                borderColor={'white'}
              />
          ); 
        }

        if(selectedSection && showTransitionPage){
          
            return(
                <SectionTransitionComponent onTransitionStart={handleTransitionStart} sections={sections} onTransitionBack ={handleTransitionBack} />
            );
        }else{
            return(
                <StyledSectionParentComponent selectedSection={selectedSection} sections={sections} completed={global.completed} showRequiredModal={global.showRequiredModal} onSectionChange={handleTransitionNext} />
            );
        }
    }

    return(
        <StyledRoot>          
            <StyledTop>        
                <CenteredRow>              
                    <StyledTitleInstructionWrapper style={{flexDirection: isBigScreen ? 'row' : 'column', paddingBottom: isBigScreen ? 0 : '2em'}}>
                      <StyledTitle>                    
                          <StyledTitleText>{title} </StyledTitleText>
                          <StyledSeparateBar><DividerComponent /></StyledSeparateBar>
                          { (selectedSection && selectedSection.type && selectedSection.type.split('.')[0] === 'multiForm') || showTransitionPage || (selectedSection && selectedSection.type && selectedSection.type.split('.')[0] === 'conditional') ? <></> : <StyledQuestionNumber>{qnumb === 0 || qnumb === undefined ? '' : qnumb + ' Questions'}</StyledQuestionNumber> }
                      </StyledTitle>
                      { selectedSection && showTransitionPage ? <PrimaryTextWrapper style={{maxWidth: isBigScreen ? '56%' : '100%'}} dangerouslySetInnerHTML={{__html: selectedSection.primaryText}}></PrimaryTextWrapper> : <StyledInstruction style={{maxWidth: isBigScreen ? '56%' : '100%'}} dangerouslySetInnerHTML={{__html: selectedSection && selectedSection.instructions ? selectedSection.instructions : ''}}></StyledInstruction> }               
                    </StyledTitleInstructionWrapper>            
                </CenteredRow>
                <StyledWavyLineContainer>
                    <SectionBorderComponent />
                </StyledWavyLineContainer>               
            </StyledTop>
            {renderContainerComponent()}
        </StyledRoot>        
    )
}

export default ScreeningContainerComponent;