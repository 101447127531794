
    import React, { useEffect } from 'react';
    import styled from 'styled-components/macro'; 
    import { ThemeProvider } from 'styled-components';
    import DividerComponent from '../DividerComponent/DividerComponent';
    import themes from '../../Themes/themes';
    import SectionBorderComponent from '../ScreeningContainerComponent/SectionBorderComponent';
    import FooterComponent from '../FooterComponent/FooterComponent';   
    import { up } from 'styled-breakpoints';
    import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';
    import { Redirect } from 'react-router-dom';
    import { useDispatch, useSelector } from 'react-redux';
    import { Helmet } from "react-helmet";
    
    const StyledThemeWrapper = styled(ThemeProvider)``;

    const StyledRoot = styled.div`
        background-color: ${props => props.theme.background};                     
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    `;

    const StyledTopWrapper = styled.div`                
        background-color: ${props => props.theme.main};
        padding-top: 50px;                
    `;

    const StyledTop = styled.div`
        margin: 0 2em;
        ${up('lg')}{
            width: 70%;
            max-width: 1300px;
            margin: 0 auto;
        }
    `;

    const StyledHeader = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;     
        margin-bottom: 70px;           
        ${up('lg')}{
            margin-bottom: 110px;
        }
    `;

    const StyledTitle = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 50px;
    `;

    const StyledTitleText = styled.div`         
        font-weight: bold;
        font-size: 2em;
        color: ${ props => props.theme.card };
        margin-bottom: 16px;           
    `;

    const StyledSectionCountText = styled.div`        
      color: ${props => props.theme.headerText};
      font-weight: 600;      
      font-size: 25px;
      margin-top: 16px;
    `;

    const StyledTextArea = styled.div`        
        margin: 0 2em;    
        min-height: 600px;
        flex: 1;
        ${up('lg')}{
            width: 70%;
            max-width: 1300px;
            margin: 0 auto;
        }
    `;        

    const StyledSubtitle = styled.div`        
        font-size: 2em;
        font-weight: bold;
        color: ${ props => props.theme.main };
        margin-bottom: 48px;
    `;        
    
    const StyledHeaderImage = styled.img.attrs({
        src: './images/logo_url_white_transparent.png'
    })`
        height: 30px;                
    `;

    const StyledSeparateBar = styled.div`
        dispaly: flex;     
        width: 42px;
        ${up('lg')}{
            width: 52px;
        }
    `;   
    
    const StyledList = styled.ul`
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 48px;
      max-width: 600px;
    `;

    const StyledBullet = styled.div`
      height: 10px;
      width: 10px;
      min-width: 10px;
      min-height: 10px;
      margin-right: 1em;  
      border-radius: 100%;
      position: relative;
      top: 5px;
      background: linear-gradient(90deg, #F7C634 0%, #F79334 100%);  
    `;

    const StyledListItem = styled.li`
      display: flex;
      flex-direction: row;  
      font-size: 18px;
      color: #717171;
      align-items: flex-start;
      margin-bottom: 24px;
    `;  

    const StyledButton = styled(PrimaryButtonComponent)`
	    margin-bottom: 1em;
    `;

    const scrollToTop = () => {
        window.scrollTo({
          top: 0
        });
      }
  
    const WelcomePageComponent = () => {               
      const [goNext, setGoNext] = React.useState(false);
      const sections = useSelector((state) => state.sections);
      const global = useSelector((state) => state.global);
      const dispatch = useDispatch();

      scrollToTop();

      useEffect(() => {
        if (sections && sections.length === 0) {
            dispatch({ type: 'INIT_ASSESSMENT' });
          }
      },[])

      if(global && global.goRegister) {
        return (<Redirect to='/registration'/> );
      }

      if(global && global.completed){
        return(
          <Redirect to='/thankyou' />
        );
      }
      
      return goNext? <Redirect to="/home" /> : (
            sections.length > 0 && !sections[0].init && <StyledThemeWrapper theme={ themes['default']}>   
                <StyledRoot>
                    <Helmet>
                    <title>Catalyte screening</title>
                    </Helmet>                                   
                    <StyledTopWrapper>
                        <StyledTop>
                            <StyledHeader>
                                <StyledHeaderImage />                                  
                            </StyledHeader>  
                            <StyledTitle>
                                <StyledTitleText> {'Welcome to Catalyte Screening!'} </StyledTitleText> 
                                <StyledSeparateBar><DividerComponent /></StyledSeparateBar>    
                                <StyledSectionCountText>{sections.length} Section{sections.length === 1? '' : 's'}</StyledSectionCountText>                   
                            </StyledTitle> 
                        </StyledTop> 
                        <SectionBorderComponent /> 
                    </StyledTopWrapper> 
                    <StyledTextArea>
                      <StyledSubtitle>{'Screening Tips'}</StyledSubtitle>
                      <StyledList>
                        <StyledListItem><StyledBullet /><div>The screening should take approximately two hours. Use the bar at the top to track your progress through the sections.</div></StyledListItem>
                        <StyledListItem><StyledBullet /><div>Any questions marked with an asterisk (*) require an answer.</div></StyledListItem>
                        <StyledListItem><StyledBullet /><div>You may leave and return to your screening at any time. Refer to the instructions in your welcome email.</div></StyledListItem>
                        <StyledListItem><StyledBullet /><div>The screening will time out after 30 minutes of inactivity, and you will be prompted to log back in via the Odyssey Portal.</div></StyledListItem>                        
                      </StyledList>                                                                            
                      <StyledButton onClick = {() => {setGoNext(true);}} id={'submit-btn'} text={'Let\'s Get Started!'}></StyledButton>       
                    </StyledTextArea>    
                    <FooterComponent />                    
                </StyledRoot>  
            </StyledThemeWrapper>   
        )
    }
export default WelcomePageComponent;