import React from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components/macro';

const WavyLine = styled.path`
  fill:  ${props => props.theme.main};
`;
const StyledWavyLineContainer = styled.div`
  display: inline-block;   
  width: 100%; 
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  background-color: ${props => props.theme.background};
  height: 130px;   
  ${up('xl')}{
    height:200px;
  }
 
`;

const StyledSvg = styled.svg`
`;

const SVGComponent = () => {    
        return (
  <StyledWavyLineContainer>     
    <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 350 1920.017 150">    
      <WavyLine id="Path_1489" d="M41.441,111.16H1961.458V499.655s-266.36,99.2-636.231,99.2-503.438-99.2-834.745-99.2S41.441,571.919,41.441,571.919Z" transform="translate(-41.441 -111.16)" opacity="1" />
    </StyledSvg>
  </StyledWavyLineContainer>   
        );       
    }

export default SVGComponent;