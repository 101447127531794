import React from 'react';
import styled from 'styled-components/macro';

const Root = styled.div`
  display: flex;
  justify-content: center;
`;

const RadioButtonWrapper = styled.div`
    background: transparent linear-gradient(90deg, ${props => props.theme.secondary} 0%, ${props => props.theme.tertiary} 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 100%;
    margin: 0;
    padding: 0;
    height: 18px;
    width: 18px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const RadioButton = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    height: 14px;
    width: 14px;
    background: ${props => props.theme.background};
    margin: 0;
    border-radius: 100%;
    padding: 0;
    box-shadow: -3px 2px 10px ${props => props.theme.formShadow};
    &:checked{
        background: transparent linear-gradient(90deg, ${props => props.theme.secondary} 0%, ${props => props.theme.tertiary} 100%) 0% 0% no-repeat padding-box;
        box-shadow: none;
    }
    &:focus{
        border: none;
        outline: none;
    }
    cursor: pointer;
`;

const RadioButtonLabel = styled.label`
    padding-left: 1em;
    color: ${ props => props.theme.label };
    font-weight: 600;
    font-size: small;
`;

const SelectedRadioButtonLabel = styled.label`
    padding-left: 1em;
    color: ${ props => props.theme.accentText };
    font-weight: 600;
    font-size: small;
`;

export const RadioButtonComponent = (props) => {

    const handleClick = (e) => {
        props.onChange(props.id);
    }  
 
    const renderLabel = () => {
        if(!props.label){
            return;
        }

        if(props.checked && !props.isSelectedUnbold){
            return (
                <SelectedRadioButtonLabel htmlFor={props.id} style={props.labelStyle}>{props.label}</SelectedRadioButtonLabel>
            );
        }else{
            return(
                <RadioButtonLabel htmlFor={props.id} style={props.labelStyle}>{props.label}</RadioButtonLabel>
            );
        }
    }   

    return (
        <Root className={props.className} onClick={handleClick}>
            <RadioButtonWrapper>
                <RadioButton type="radio" name={props.group} id={props.id} checked={!!props.checked} readOnly></RadioButton>
            </RadioButtonWrapper>
            { renderLabel() }
        </Root>
    );
};

export default RadioButtonComponent;