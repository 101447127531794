import { ContactSupportOutlined } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import MobileSecondaryFormComponent from '../../components/RegistrationComponent/MobileSecondaryFormComponent';
import SecondaryFormComponent from '../../components/RegistrationComponent/SecondaryFormComponent';

const RegistrationSecondaryFormContainer = (props) =>{
    let secondaryFormProps = {...props};
   
    //** Recaptcha **/
    const handleLoaded = _ => {
        window.grecaptcha.ready(_ => {
          window.grecaptcha
            .execute("6Lfvaz4aAAAAAKj9Lvx8KB-3AqEGt3TrbTVc-x3C", { action: "registration" })
            .then(token => {
            });
        })
    }

    useEffect(() => {
        if(process.env.REACT_APP_env_cookie_domain === 'catalyte.io'){
            const script = document.createElement("script");
            script.src = "https://www.google.com/recaptcha/api.js?render=6Lfvaz4aAAAAAKj9Lvx8KB-3AqEGt3TrbTVc-x3C";
            script.id = "g-recaptcha-script";
            script.addEventListener("load", handleLoaded);
            document.body.appendChild(script);
        }
    }, [])

    //** Field value state **/
    const [password, setPassword] = React.useState(props.password);
    const [confirmedPassword, setConfirmedPassword] = React.useState(props.confirmedPassword);
    const [consent, setConsent] = React.useState(props.consent); 
    const [aboutUs, setAboutUs] = useState(props.aboutUs);  
    const [isShowPassword, setIsShowPassword]= useState(false);
    const [isShowPWEyeButton, setIsShowPWEyeButton]= useState(null);
    const [isShowCPWEyeButton, setIsShowCPWEyeButton]= useState(null);    
    const [global, setGlobal] = useState(props.globalState);

    //** Field Validation state **/
    const [isValidPassword, setValidPassword] = React.useState(null);
    const [isValidConfirmedPassword, setValidConfirmedPassword] = React.useState(null);
    const [isValidConsent, setValidConsent] = React.useState(null);
    const [isSubmitAction, setIsSubmitAction]= useState(false); 
    const [isValidAboutUs, setValidAboutUs] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [isFirstPasswordAttempt, setIsFirstPasswordAttempt] = useState(true);

    secondaryFormProps.password = password;
    secondaryFormProps.confirmedPassword = confirmedPassword;
    secondaryFormProps.consent = consent;
    secondaryFormProps.aboutUs = aboutUs;
    secondaryFormProps.isValidPassword = isValidPassword;
    secondaryFormProps.isValidConfirmedPassword = isValidConfirmedPassword;
    secondaryFormProps.isValidConsent = isValidConsent;
    secondaryFormProps.isSubmitAction = isSubmitAction;
    secondaryFormProps.isValidAboutUs = isValidAboutUs;
    secondaryFormProps.isValid = isValid;
    secondaryFormProps.isFirstPasswordAttempt = isFirstPasswordAttempt; 

    //** Field validator functions **/
    const validatePassword = secondaryFormProps.validatePassword = (v) => {
        if(!v){
            return false;
        }

        const validLength = v.length > 7;
        const hasNums = /\d/.test(v);
        const hasSpecial = !/^[A-Za-z0-9]*$/.test(v);
        const hasMixedCase = /[a-z]/.test(v) && /[A-Z]/.test(v);

        let validPassword = validLength && hasNums && hasSpecial && hasMixedCase;
        return validPassword;
    }

    const validateConsent = secondaryFormProps.validateConsent = (v) => {
        if(!v || v !== 'agree'){
            return false;
        }
        return true;
    }

    const validateAboutUs = secondaryFormProps.validateAboutUs = (ab)=>{    
        return true;
     }

    const getIsValid = secondaryFormProps.getIsValid = (isSubmitAction) => {
        let cognitoUser = global && global.cognitoUser;

        if(!cognitoUser){
            let passwordIsValid;
            let confirmedPasswordIsValid;
            let consentIsValid;      
     
            if(isSubmitAction){
                passwordIsValid = validatePassword(password);
                setValidPassword(passwordIsValid);
                confirmedPasswordIsValid = (confirmedPassword === password) && confirmedPassword;
                setValidConfirmedPassword(confirmedPasswordIsValid);
                consentIsValid = validateConsent(consent);
                setValidConsent(consentIsValid);           
            }else{
                passwordIsValid = validatePassword(password);
                confirmedPasswordIsValid = confirmedPassword === password;
                consentIsValid = validateConsent(consent);    
            }
     
            return passwordIsValid && confirmedPasswordIsValid && consentIsValid;
        }else{
            let consentIsValid;      
     
            if(isSubmitAction){
                consentIsValid = validateConsent(consent);
                setValidConsent(consentIsValid);           
            }else{
                consentIsValid = validateConsent(consent);    
            }
     
            return consentIsValid;
        }
    }

    secondaryFormProps.getPasswordHelperText = (v) => {
        if(!v) return 'Password is required.'; 
        const invalidLengthMsg = 'Password must be at least 8 characters.\n';
        const invalidNums = 'Password must contain at least one number.\n';
        const invalidSpecial = 'Password must contain at least one special character.\n';
        const invalidCase = 'Password must contain upper and lower case characters.';

        const validLength = v.length > 7;
        const hasNums = /\d/.test(v);
        const hasSpecial = !/^[A-Za-z0-9]*$/.test(v);
        const hasMixedCase = /[a-z]/.test(v) && /[A-Z]/.test(v);

        let helperText = '';

        helperText = validLength ? helperText : helperText + invalidLengthMsg;
        helperText = hasNums ? helperText : helperText + invalidNums;
        helperText = hasSpecial ? helperText : helperText + invalidSpecial;
        helperText = hasMixedCase ? helperText : helperText + invalidCase;

        return helperText;
    }

    //** Field change handlers **/
    secondaryFormProps.onPasswordChange = (v,f) => {
        if(f) setIsShowPWEyeButton(true);  
        setPassword(v);
        if(!isFirstPasswordAttempt){          
            if(isValidConfirmedPassword !== null){
                setValidConfirmedPassword(v === confirmedPassword);
            }
        }
    }

    secondaryFormProps.getConsentHelperText = (v) => {
        if(!v || v != 'agree'){
            return 'Please read and agree to the Terms and Conditions.'
        
        }else{
            return '';
        }
    }

    secondaryFormProps.onConfirmedPasswordChange = (v,f) => {
        if(f) setIsShowCPWEyeButton(true);  
        setConfirmedPassword(v);
        setValidConfirmedPassword(v === password);
    }  

    //** Field blur handlers **/
    secondaryFormProps.onPasswordBlur = (v) => {
        setIsFirstPasswordAttempt(false);        
        setValidPassword(validatePassword(v));       
    }

    secondaryFormProps.onConfirmedPasswordBlur = (v) => {       
          setValidConfirmedPassword(v === password);        
    }

    secondaryFormProps.onConsentChange = (v) => { 
        let newV = consent === 'agree' ? 'no' : 'agree';       
        setValidConsent(validateConsent(newV));
        setConsent(newV);
    }
 
    secondaryFormProps.onAboutUsChange = (v) =>{
        setValidAboutUs(validateAboutUs(v));
        setAboutUs(v);
    }

    //** Key Handlers **//
    secondaryFormProps.handleOnKeyDown = (e) => {
        if(isValid && (e.key === "Enter")){
            secondaryFormProps.handleSubmit(e);
          }
    }

    //** Click Handlers **//
    secondaryFormProps.handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitAction(true);
        if(getIsValid(true)){ 
            props.onClickSubmit({ consent, password, aboutUs });
        }
    }   

    secondaryFormProps.handleBack = (e) => {
        e.preventDefault();
        props.onClickBack( { consent } );
    } 

    secondaryFormProps.handleEyeClick =(e) =>{ 
        setIsShowPassword(!isShowPassword);
    } 

    secondaryFormProps.onPWFocus =(id) =>{ 
        if(id === 'pwd-input' || id === 'pwd-inputeye'){
          setIsShowPWEyeButton(true);
          setIsShowCPWEyeButton(false);          
        }
        else if(!id)
        {  
            setIsShowPWEyeButton(false);
            setIsShowCPWEyeButton(false)
        } 
    }

    secondaryFormProps.onCPWFocus =(id) =>{
        if(id ==='cnfrm-input' || id === 'cnfrm-inputeye'){
            setIsShowCPWEyeButton(true);
            setIsShowPWEyeButton(false);           
        }
        else if(!id)
        {  
            setIsShowPWEyeButton(false);
            setIsShowCPWEyeButton(false);   
        }                           
    } 
   
    secondaryFormProps.passwordType = isShowPassword ? 'text' : 'password';
    secondaryFormProps.confirmPasswordType = isShowPassword ? 'text' : 'password';
    secondaryFormProps.isShowPWEyeButton = isShowPWEyeButton;
    secondaryFormProps.isShowCPWEyeButton = isShowCPWEyeButton;

    useEffect(() => {
        setIsValid(getIsValid());
    }, [password, confirmedPassword, consent, aboutUs, getIsValid])

    useEffect(() => {
        setGlobal(props.globalState);
    }, [props.globalState])

    if(props.isSmallScreen){
        return (
          <MobileSecondaryFormComponent {...secondaryFormProps}></MobileSecondaryFormComponent>
        )
      }else{
        return(
          <SecondaryFormComponent {...secondaryFormProps}></SecondaryFormComponent>
        )
      } 
  }

export default RegistrationSecondaryFormContainer;
