export const validateDateFormat = (inputText) =>{    
  
    let dateformat =  /^\d{2}\/\d{2}\/\d{4}$/;
    if(dateformat.test(inputText))
    {            
        const opera1 = inputText.includes('/');
        const opera2 = inputText.includes('-');        
        let pdate;       
        if (opera1)
        {
            pdate = inputText.split('/');
        }
        else if (opera2)
        {
            pdate = inputText.split('-');
        }
        let mm = parseInt(pdate[0]);
        let dd  = parseInt(pdate[1]);
        let yy = parseInt(pdate[2]);
 
        if(mm >12) {
            return false;
        } 
        if(yy > 2021 || yy <1920){
            return false;
        }  
    
        let ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
        if (mm===1 || mm > 2)
        {
            if (dd>ListofDays[mm-1])
            {        
                return false;
            }
        }
        if (mm===2)
        {
            let lyear = false;
            if ( (!(yy % 4) && yy % 100) || !(yy % 400)) 
            {
                lyear = true;
            }
            if ((lyear===false) && (dd>=29))
            {        
                return false;
            }
            if ((lyear===true) && (dd>29))
            {          
                return false;
            }
        }
        return true;
    }
    else
    {     
        return false;
    }
}

export const setDateFormat =(answer, requiredLength) =>{
         
    if(answer.length > 2 && answer.length <6)
    {
        answer = answer.replace(/.{2}/,'$&/');                 
    }
   
    if(answer.length >= 6 && answer.length <11)
    {  
        answer = answer.replace(/.{2}/,'$&/'); 
        answer = answer.replace(/.{5}/,'$&/'); 
    } 
    if(answer.length <= answer)  {
        return answer;
    }
    else
    {
        return answer.slice(0,requiredLength); 
    }        
}

export const dateValidation =(answer ) =>{                
    answer = answer ? answer.replace(/\D/g,'') : '';       
    if(answer){                
        return setDateFormat(answer, 'xx/xx/xxxx'.length); 
    }    
    return null;         
}

export const getClient = () => {
    let client = 'catalyte'

    switch(window.location.pathname){
      case '/createoppcolumbia':
        client = 'createoppcolumbia';
        break;
      default:
        client = 'catalyte';
    }

    let clientCookie = document.cookie
      .split(';')
      .filter((v, i) => { 
        return v.split('=')[0]
        .trim() === 'client' 
      });

    if(clientCookie.length > 0){
      client = clientCookie[0].split('=')[1];
    }

    return client;
  }

export const getClientBaseUrl = () => {
    let baseUrl = process.env.REACT_APP_env_url
    let client = getClient()

    if(client && client !== 'catalyte'){
        let urlparts = baseUrl.split('//')
        let clientUrl = `${urlparts[0]}//${client}.${urlparts[1]}`
        return clientUrl
    }
    return baseUrl
}

