import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import TextInputComponent from '../TextInputComponent/TextInputComponent';
import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';
import configs from '../../configuration/configuration.json';
import { ThemeContext } from 'styled-components';

const InputGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const FormBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 100;
    background-color: ${props => props.theme.card};
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
`;

const ButtonRow = styled.div`
    margin-left: 1em;
    margin-right: 1em;
    justify-content: space-between;
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 2em;
    padding-top: 3em;
`;

const PrimaryFormComponent = (props) =>{
    const themeContext = useContext(ThemeContext);

    const firstName = props.firstName;
    const isValidFname = props.isValidFname;
    const onFnameChange = props.onFnameChange;
    const onFnameBlur = props.onFnameBlur;
    const lastName = props.lastName;
    const isValidLname = props.isValidLname;
    const onLnameChange = props.onLnameChange;
    const onLnameBlur = props.onLnameBlur;
    const isValidEmail = props.isValidEmail;
    const getEmailHelper = props.getEmailHelper;
    const onEmailChange = props.onEmailChange;
    const onEmailBlur = props.onEmailBlur;
    const email = props.email;
    const onPhoneBlur = props.onPhoneBlur;
    const onPhoneChange = props.onPhoneChange;
    const phone = props.phone;
    const isValidPhone = props.isValidPhone;
    const isValidAddress = props.isValidAddress;
    const onAddressChange = props.onAddressChange;
    const onAddressBlur = props.onAddressBlur;
    const address = props.address;
    const onAptChange = props.onAptChange;
    const apt = props.apt;
    const isValidCity = props.isValidCity;
    const onCityChange = props.onCityChange;
    const onCityBlur = props.onCityBlur;
    const city = props.city;
    const isValidState = props.isValidState;
    const onStateChange = props.onStateChange;
    const onStateBlur = props.onStateBlur;
    const state = props.state;
    const isValidZip = props.isValidZip;
    const onZipcodeChange = props.onZipcodeChange;
    const onZipCodeBlur = props.onZipCodeBlur;
    const zipcode = props.zipcode;
    const isValid = props.isValid;
    const handleSubmit = props.handleSubmit;
    const cognitoUser = props.globalState.cognitoUser

    return (
        <FormBox>
            <InputGroup>
                {
                (!cognitoUser || (!cognitoUser.attributes.given_name || !cognitoUser.attributes.family_name)) &&
                <>
                    <TextInputComponent
                        id={'fname-input'}
                        error={ isValidFname === null ? false : !isValidFname }
                        helperText={ 'Must be at least one and less than 256 characters' }
                        type="text"
                        onChange={onFnameChange}
                        value={firstName}
                        backgroudColor={themeContext.quarterly}
                        onBlur={onFnameBlur}
                        required={true}
                        isValid={isValidFname}
                        label='First Name'
                    />
                    <TextInputComponent
                        id={'lname-input'}
                        error={ isValidLname === null ? false : !isValidLname }
                        helperText={'Must be at least one and less than 256 characters'}
                        type="text"
                        onChange={onLnameChange}
                        onBlur={onLnameBlur}
                        isValid={isValidLname}
                        value={lastName}
                        backgroudColor={themeContext.quarterly}
                        required
                        label='Last Name'
                    />
                    {
                    !cognitoUser &&
                    <TextInputComponent
                        id={'email-input'}
                        error={(isValidEmail !== null && (props.userExists || !isValidEmail)) ? true : false}
                        helperText={getEmailHelper()}
                        type="text"
                        onChange={onEmailChange}
                        onBlur={onEmailBlur}
                        value={email}
                        backgroudColor={themeContext.quarterly}
                        isValid={isValidEmail !== null && (!props.userExists && isValidEmail)}
                        label='Email'
                        required
                    />
                    }
                </>
                }
                <TextInputComponent
                    id={'phone-input'}
                    error={ isValidPhone === null ? false : !isValidPhone }
                    isValid={ isValidPhone }
                    helperText={'Please enter a valid phone number'}
                    type="tel"
                    onChange={onPhoneChange}
                    onBlur={onPhoneBlur}
                    backgroudColor={themeContext.quarterly}
                    value={phone}
                    label='Phone'
                    required
                  />
                <TextInputComponent
                    id={'address-input'}
                    error={ isValidAddress === null ? false : !isValidAddress }
                    isValid={ isValidAddress }
                    helperText={'Please enter a valid address'}
                    type="text"
                    onChange={onAddressChange}
                    onBlur={onAddressBlur}
                    backgroudColor={themeContext.quarterly}
                    value={address}
                    label='Address'
                    required
                />
                <TextInputComponent
                    id={'apt-input'}
                    type="text"
                    onChange={onAptChange}
                    value={apt}
                    backgroudColor={themeContext.quarterly}
                    label='Apt/Suite'
                />
                <TextInputComponent
                    id={'city-input'}
                    error={ isValidCity === null ? false : !isValidCity }
                    isValid={ isValidCity }
                    helperText={'Please enter a valid city'}
                    type="text"
                    onChange={onCityChange}
                    onBlur={onCityBlur}
                    value={city}
                    label='City'
                    backgroudColor={themeContext.quarterly}
                    required
                />
                <TextInputComponent
                    id={'state-input'}
                    error={ isValidState === null ? false : !isValidState }
                    isValid={ isValidState }
                    helperText={ 'Please enter a valid state abbreviation' }
                    type="text"
                    onChange={onStateChange}
                    onBlur={onStateBlur}
                    value={state}
                    label='State'
                    backgroudColor={themeContext.quarterly}
                    required
                />
                <TextInputComponent
                    id={'zip-input'}
                    error={ isValidZip === null ? false : !isValidZip }
                    isValid={ isValidZip }
                    helperText={ 'Please enter a valid zip code' } 
                    type="text"
                    onChange={onZipcodeChange}
                    onBlur={onZipCodeBlur}
                    value={zipcode}
                    label='Zip'
                    backgroudColor={themeContext.quarterly}
                    required
                />
            </InputGroup>
            <ButtonRow>
              <PrimaryButtonComponent
                    id={'next-btn'}
                    innactive={!isValid}
                    onClick={handleSubmit}
                    text={'Next'}
              ></PrimaryButtonComponent>
            </ButtonRow>
        </FormBox>
      );
  }

export default PrimaryFormComponent;
