import React, { useEffect, useState, useRef, useMemo } from "react";
import styled from 'styled-components/macro';
import ArrowDropDownIcon from '@material-ui/icons/ExpandMore';
import ArrowUpIcon from '@material-ui/icons/ExpandLess';

const StyledRoot = styled.div`    
    margin-left: 0em;
    width:100%;
    display: flex;
    flex-direction: column; 
`;
const StyledLabel = styled.div` 
    display: flex;    
    vertical-align: top;
    width: 100%;
`;

const LabelText = styled.div`
    color: ${ props => props.theme.label };
    font-weight: 600;
    font-size: medium;
    margin-left: .5em;  
    vertical-align: top;
    padding-bottom: 1em;
`;

const RequiredSymbol = styled.div`
    color: red;
    margin-left: .2em;
    margin-bottom: 0; 
`;

const StyledWrapper = styled.div`
    display: flex;   
`;
const StyledEnterBoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;  
    background-color: white;
    padding-top: 0px;
`;

const StyledDropdownContainer = styled.div`
    position: absolute;
    right: 0;
    top: 38px; 
    overflow-x: hidden; 
    background: #fcfcfc 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 15px #CECECE; 
    border-radius: 2px 2px 12px 12px;
    opacity: 1;
    padding-bottom:5px;
    z-index: 3;   
`;

const StyledDropdownBox = styled.div`   
    border-width: 0px 0px 0px 0px;
    padding-left: 10px; 
    background-color: #fcfcfc; 
    cursor: pointer;
    &:focus{
      border: none;
      outline: none;
    }  
`;

const StyledDropdownList = styled.span`
    display: flex;
    font-size: 0.8em;
    opacity: 0.9;
    tableIndex: 0;
    &:hover {
      opacity: 1;
      background-color: #d8eddd;
    } 
`;

const TextArrowWrapper = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 40px #00000024;
    border-radius: 11px;
    opacity: 1;
    display: flex;  
`;

const DummyLine = styled.span`
    background-color: #fcfcfc;
    display: flex;
    height: 15px;
`;
const StyledArrow = styled.div` 
    padding-top: 15px;
    padding-right:13px;
    cursor: pointer;
`;
const StyleLabelText = styled.div`
   color: #8B8B8B;
   font-weight: bold;
`;

const CheckSymbol = styled.div`
    display: flex; 
    float: right;  
    flex-direction: column;
    justify-content: right;
    color: green;
    margin-left: 1em;
    background-image: url(${ props => props.theme.validIcon });
    width: 1em;
    background-repeat: no-repeat;
    background-position-y: center;     
`;

const StyledTextInput = styled.input`
    border: none;
    min-width: 0em;
    margin-left: 0.5em;
    border-radius: 1em;
    font-size: 1em;
    background-color: inherit;
    flex-grow: 1;
    color: ${props => props.theme.inputText};
    &:hover{
        border: none;
    }
    &:focus{
        border: none;
        outline: none;
    }
`;

const DropdownShadow = styled.div`
   box-shadow: 1px 30px 20px #CECECE inset;
`;

const ValueSpace = styled.div`
    width: 16em;
    padding-left: 0.5em; 
`;
const HighLightValueSpace = styled.div`    
    color: ${props => props.theme.accentText};
    padding-left: 0.5em;  
`;

const DropdownMultipleSelectComponent = (props) => { 

  const [display, setDisplay] = useState(false);
  const wrapperRef = useRef(null);
   // set check marks based on saved answer
   const valueArray =[];
   const qanswer = props.question.answer ? props.question.answer : [];
   if(qanswer){
      qanswer.forEach(v =>{
        if(v.input === true){
            valueArray.push(v.value);
        }
      }); 
    }
  
   let dropdownOptionList =props.question.options; 
   let options = [];
    //sorting the option
   if(props.question.type.toLowerCase().indexOf('ordered') >=0){
        dropdownOptionList = dropdownOptionList.sort((a,b) => (a.order > b.order) ? 1 : -1);           
   }else{
        dropdownOptionList = dropdownOptionList.sort((a,b) => (a.value > b.value) ? 1 : -1);        
   }
   //convert to value array and put 'Other' or 'Decline to Answer' to the last of the list
   let otherItem='', placeholderItem='Please select all that apply';
   let dropDownHeight = 0, dropdownWidth = 0;

   dropdownOptionList.forEach((v,i) =>{
        if(v.valueType.toLowerCase().indexOf('placeholder') >-1){
          placeholderItem = v.value;
        }
        else if (v.value.toLowerCase().indexOf('other') <0 && v.value.toLowerCase().indexOf('decline to answer') <0 ){
          options.push(v.value);
        }
        else{
          otherItem = v.value;
        }
        //determine the width of dropdown based on the width of text string
        if(v.valueType !== 'placeholder' && dropdownWidth < v.value.length){
          dropdownWidth = v.value.length;
        }
   });

   if(otherItem) {
    options.push(otherItem); // push 'other' or 'decline to answer' option to the last
   } 
  
   // set values for display; to avoid re-rendering multiple times we do not want to put these two component state variables on the top
  const answerString = valueArray.join(' & ');  
  const [value, setValue] = useState(answerString.length > 30 ? answerString.slice(0, 30) +'...' : answerString); 
  const [isChecked, setIsChecked]= useState(valueArray);
  const checkDistance ={};

  dropDownHeight = options[0].toLowerCase().indexOf('placeholder') >=0 ? (options.length -1) * 39 :options.length * 39; 
  dropdownWidth = dropdownWidth * 9;
  if(dropdownWidth < 250){
    dropdownWidth = 250; //we do not want the dropdown too small
  } 
  if(dropDownHeight > 285)  {
      dropDownHeight = 285; //do not want the dropdown too heigh
  }
  checkDistance.width = dropdownWidth -55; // the space between text and check mark
  const containerStyle = {
    height: dropDownHeight,
    paddingTop: 12        
  };

  const textArrowStyle = {
    paddingLeft: props.textShift,
    width: dropdownWidth, 
    height: 48,
    zIndex: 10
  };
 
  const textFieldStyle = {
    width: dropdownWidth - 15, 
    height: 48, 
    paddingTop: 5, 
    paddingLeft: props.textShift || 10
  }

  const boxStyle = {
    width: dropdownWidth, 
    height: 48,                       
    borderBottomLeftRadius: 20,
    borderBottomRightRadius:  20,
    borderTopRightRadius:  20,
    borderTopLeftRadius: 20
  }

  const dropdownStyle = {
    width: options.length > 12 ? dropdownWidth - 20 : dropdownWidth   
  }
 
  const functionHolder = {};  

  functionHolder["func_"+props.question.questionID] = event => {
   
    const { current: wrap } = wrapperRef;   
    if (wrap && !wrap.contains(event.target)) {    
        setDisplay(false); 
        if(props.onChange){  
          props.onChange(value);  
        }         
    }   
 
  };
  useEffect(() => {
      window.addEventListener("mousedown", functionHolder["func_"+props.question.questionID]);
    return () => {
      window.removeEventListener("mousedown", functionHolder["func_"+props.question.questionID]);
    };
  },[props.question.questionID, functionHolder]);

  const updateInput = (input, idx) => {  
    setDisplay(true);
    let output="";
    let selectedItems = isChecked;
    
    //if 'decline to answer' or 'other' is checked, reset the selections, and toggles the value
    if(idx === options.length -1 && (input.toLowerCase() === 'decline to answer' || input.toLowerCase() === 'other')){
        selectedItems = new Array(options.length); // reset check list
       
        if((input.toLowerCase() === 'decline to answer' || input.toLowerCase() === 'other') && !isChecked.includes(input)){
          selectedItems.push(input);
        }        
    }
    else {
        
        if(selectedItems.includes(input)){
          selectedItems = selectedItems.filter((e) => { return e !== input});
        }
        else{
          selectedItems.push(input);
        }
        //unselect 'decline to answer' or 'other' if the last is
        selectedItems = selectedItems.filter((e) => { return e.toLowerCase() !== 'decline to answer' && e.toLowerCase() !== 'other'});           

    }

    setIsChecked(selectedItems);
    let newAnswerVal = props.question.answer ? props.question.answer : []; 
 
    //dipsplay all the options which were selected
    newAnswerVal.forEach(e => {
      if(selectedItems.includes(e.value)){
        e.input = true;
        output = output + e.value + ' & ';
      }
      else{
        e.input = false;
      }
    });

    output = output.slice(0, output.length - 3);//remove last '&'   
    setValue(output.length > 30 ? output.slice(0, 30) + ' ... ' : output);
    if(props.question != null){ 
      props.onChange({ "answer": newAnswerVal, "uuid": props.question.uuid, "id": props.question.questionID}); 
    }
    else{
      props.onChange(output);
    }
  };

  const getPrompts = (prompts) =>{
 
    if(!prompts){
      return '';
    }
   
    return(
      prompts.map((prompt, i) =>{
          return (<div key={'prompt_'+i} variant="body1" color="inherit">{prompt.value}</div>);
      })
    );
  }

  const handleTextFieldOnClick = () => {  
    setDisplay(!display); 
  }

  const handleArrowOnClick = () =>{  
      setDisplay(!display);  
  }
  
  const renderArrow = () =>{
    if(!display){
      return (
        <ArrowDropDownIcon onClick = {handleArrowOnClick} />
      )
    }
    else{
      return (
        <ArrowUpIcon onClick = {handleArrowOnClick} />
      )
    }
  }

  return (
    <StyledRoot>   
        <StyledLabel>
              <LabelText ><StyleLabelText>{ props.label ? props.label : getPrompts(props.question ? props.question.prompts : '') }</StyleLabelText></LabelText>
                { props.required ? <RequiredSymbol>*</RequiredSymbol> : '' }             
        </StyledLabel>
        <StyledWrapper>             
                <StyledEnterBoxWrapper ref={wrapperRef} style ={boxStyle} >                    
                  <TextArrowWrapper style = {textArrowStyle} onClick={handleTextFieldOnClick}>
                     <StyledTextInput type={props.type ||'text'} value={value || ""} placeholder={placeholderItem} style = {textFieldStyle} disabled />                  
                    <StyledArrow onClick = {handleArrowOnClick}> {renderArrow()} </StyledArrow>
                  </TextArrowWrapper>  
                  {display && (<DropdownShadow>                
                      <StyledDropdownContainer style ={containerStyle}>                    
                        {options.map((v, i) => {                                        
                            return (
                              <StyledDropdownBox
                                onClick={() => updateInput(v,i)}                               
                                key={i}
                                zIndex="0"
                                style ={dropdownStyle}
                              >
                                <DummyLine></DummyLine>
                                <StyledDropdownList >
                                  { isChecked.includes(v) ? <HighLightValueSpace style ={checkDistance}>{v}</HighLightValueSpace> : <ValueSpace >{v}</ValueSpace> }
                                  { isChecked.includes(v) && <CheckSymbol></CheckSymbol>}
                                </StyledDropdownList>          
                              </StyledDropdownBox>
                            );
                          })}
                      </StyledDropdownContainer>
                      </DropdownShadow> 
                     )} 
                </StyledEnterBoxWrapper>                  
          </StyledWrapper>  
    </StyledRoot> 
  );
};

export default DropdownMultipleSelectComponent;
