import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import JobHistoryForm from './JobHistoryForm';
import { up } from 'styled-breakpoints';

const StyledRoot = styled.div`
`;

const StyledBody = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    ${up('lg')} {
        margin-top: 2em;
    }
`;

const BriefcaseIcon = styled.div`
    display: none;    
    ${up('lg')}{
        display: block;
        width: 37px;
        height: 34px;
        border: 3px solid #FFF;    
        position: absolute;                
        padding: 35px;
        border-radius: 50%;                
        background-image: url(./images/briefcase.svg);
        background-position: center center;
        background-repeat: no-repeat;
        opacity: 1;    
        left: 2em;
        top: -5.5em;        
    }    
`;

const ButtonWrapper = styled.div`
    display: flex;    
    flex-direction: column;
    justify-content: flex-start;   
    align-items: flex-start; 
    width: 100%;
    ${up('lg')} {
        width: 50%;    
    }
`;

const AddButton = styled.div`
    background-image: url(./images/Add.svg);    
    width: 166px;
    height: 152px;
    cursor: pointer;
    margin-left: 2em;
    border-radius: 35px;
    ${up('lg')} {
        margin-top: ${props => props.visibleQuestionCount%2===0 ? 0 : '3em'};
        margin-left: ${props => props.visibleQuestionCount%2===0 ? 0 : '1em'};
    }
`;

const JobHistoryComponent = () => {
    const dispatch = useDispatch();
    const selectedSection = useSelector((state) => state.selectedSection);
    const [orderedGroupArray, setOrderedGroupArray] = React.useState(null);

    //Renders groups of questions into JobHistoryForms
    const renderQuestionGroups = () => {
        let orderedGroups = [];
        let uniqueVisibleGroups = getUniqueVisibleGroups(selectedSection.questions);
        //Populate orderedGroupArray state variable if it is null
        if(!orderedGroupArray){
            //Build the orderedGroupArray
            orderedGroups = getOrderedGroupArray();
        //If orderedGroupArray is not null then use it
        }else{
            orderedGroups = orderedGroupArray;
        }
        //Sort the uniqueVisible groups by their index in the orderedGroup array and map to job history forms
        return uniqueVisibleGroups
        .sort((a, b) => (orderedGroups.indexOf(a) > orderedGroups.indexOf(b)) ? -1 : 1)
        .map((group, i) => {
            let groupQuestions = selectedSection.questions.filter((v, z) => {
                return v.group === group;
            })
            return(
                //Render JobHistoryForm...groupOrder is one based
                <JobHistoryForm key={selectedSection.name + group} group={group} questions={groupQuestions} groupOrder={i + 1} onHideGroup={hideQuestionGroup}/>
            )
        })
    }

    const getUniqueVisibleGroups = (questions) => {
        //Get all of the groups that are marked as visible or have at least one answer with input
        let groups = selectedSection.questions
        .filter((v, i) => {
            return v.isVisible || (v.answer && v.answer.input);
        }).map((z, x) => 
        {
            return z.group;
        });

        //Get the distinct groups return from the previous function
        return [...new Set(groups)];
    }

    //Returns a set of unique groups that questions belong to
    const getUniqueGroups = (questions) => {
        let groups = questions.map((v, i) => {return v.group});

        let uniqueGroups = [...new Set(groups)];

        return uniqueGroups;
    }

    //Build an ordered array of groups
    const getOrderedGroupArray = () => {
        let orderedGroups = [];
        let groupOrderArr = [];
        let distinct = [];
        //Grab group and order for each distinct group
        for(let question of selectedSection.questions){
            if(distinct.indexOf(question.group) < 0){
                distinct.push(question.group);
                //If the question already has an answer and group order, use that, otherwise use the group number
                groupOrderArr.push({group: question.group, groupOrder: question.answer ? question.answer.groupOrder : question.group});
            }
        }
        //Sort the groupOrderArr by group order and then map it to a new array with just the group numbers, in order
        orderedGroups = groupOrderArr
        .sort((a, b) => (parseInt(a.groupOrder) > parseInt(b.groupOrder)) ? -1 : 1)
        .map((v, i) => { return v.group });
        //Set state var
        setOrderedGroupArray(orderedGroups);
        return orderedGroups;
    }

    //Find an unrendered question group and mark it to be rendered
    const revealQuestionGroup = (e) => {
        //Get unique groups and sort them by their index in orderedGroupArray
        let orderedGroups = getUniqueGroups(selectedSection.questions)
        .sort((a, b) => (orderedGroupArray.indexOf(a) > orderedGroupArray.indexOf(b)) ? -1 : 1);

        //Get all of the groups that are not being rendered
        let emptyGroups = orderedGroups.filter((v, i) => {
            let visibleQuestionsInGroup = selectedSection.questions.filter((z, x) => {
                return v === z.group && (z.isVisible || (z.answer && z.answer.input));
            });

            return visibleQuestionsInGroup.length === 0;
        });

        //Get the first group that isn't being rendered, or null if they're all being rendered
        let nextGroup = emptyGroups.length > 0 ? emptyGroups[0] : null;

        let nextGroupOrder;

        //If we have a next group...
        if(nextGroup){
            //Remove it from the orderedGroupArray
            orderedGroupArray.splice(orderedGroupArray.indexOf(nextGroup), 1);
            //Add it to the beginning of the array
            orderedGroupArray.unshift(nextGroup);
            nextGroupOrder = getUniqueVisibleGroups(selectedSection.questions)
            .sort((a, b) => (orderedGroupArray.indexOf(a) > orderedGroupArray.indexOf(b)) ? -1 : 1)
            .indexOf(nextGroup) + 1;
            //Update state var
            setOrderedGroupArray(orderedGroupArray);
        }else{
            return;
        }

        //Update group questions with new rendering info in the store
        selectedSection.questions.forEach(q => {
            if(q.group === nextGroup){
                dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": q.uuid, "id": q.questionID, "isVisible": true, "answer": { "input": null, "groupOrder": String(nextGroupOrder)} } });
            }
        });
    }

    //Hide the form associated with group
    const hideQuestionGroup = (group) => {
        let uniqueVisibleGroups = getUniqueVisibleGroups(selectedSection.questions);
        let hiddenGroupIndex = orderedGroupArray.indexOf(group);
        //Update group questions with new rendering info in the store
        selectedSection.questions.forEach(q => {
            if(uniqueVisibleGroups.indexOf(q.group) > 0 && q.group !== group && orderedGroupArray.indexOf(q.group) < hiddenGroupIndex){
                dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": q.uuid, "id": q.questionID, "isVisible": true, "answer": { input: q.answer.input, groupOrder: q.answer.groupOrder -1 } } });
            }
        });
        //Remove the group from the orderedGroupArray
        orderedGroupArray.splice(hiddenGroupIndex, 1);
        //Push it back onto the end of the array
        orderedGroupArray.push(group);
        //Update state var
        setOrderedGroupArray(orderedGroupArray);
        //Update group questions with new rendering info in the store
        selectedSection.questions.forEach(q => {
            if(q.group === group){
                dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": q.uuid, "id": q.questionID, "isVisible": false, "answer": null } });
            }
        });
    }

    return (
        <StyledRoot>
            <StyledBody>
                <BriefcaseIcon />
                {renderQuestionGroups()}
                {
                getUniqueVisibleGroups(selectedSection.questions).length === getUniqueGroups(selectedSection.questions).length ? 
                    <></>
                : 
                    <ButtonWrapper>                        
                        <AddButton visibleQuestionCount={getUniqueVisibleGroups(selectedSection.questions).length} onClick={revealQuestionGroup}></AddButton>
                    </ButtonWrapper>
                }
            </StyledBody> 
        </StyledRoot>
    );
}

export default JobHistoryComponent;