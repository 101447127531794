import React from 'react';
import { useDispatch } from 'react-redux';
import { up } from 'styled-breakpoints';
import styled from 'styled-components/macro';
import RadioButtonComponent from '../RadioButtomComponent/RadioButtonComponent';

const StyledRoot = styled.div`
    display: flex;
    flex-direction: column;       
    height: 100%;
    width: 100%;
    max-width: 325px;    
    margin: 0 auto 3em auto;
    max-height: 600px;

    ${up('xl')}{
        width: auto;
        margin: 0 0 3em 0; 
    }
`;

const RadioGroup = styled.div`
    display: flex;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='395' height='28' viewBox='0 0 395 28'%3E%3Cdefs%3E%3Cfilter id='Rectangle_1' x='0' y='0' width='395' height='28' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='2' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='4' result='blur'/%3E%3CfeFlood flood-opacity='0.161'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(%23Rectangle_1)'%3E%3Crect id='Rectangle_1-2' data-name='Rectangle 1' width='396' height='4' rx='2' transform='translate(12 10)' fill='%23${props => props.theme.card.slice(1)}'/%3E%3C/g%3E%3C/svg%3E%0A");
    width: 100%;
    max-width: 322px;
    height: 20px;
    margin-top: auto;
    position: relative;
    left: -.5em;    
`;

const PromptText = styled.p`
    color: ${ props => props.theme.label };
    font-size: 15px;
    display: flex;    
    margin: 0;
    margin-bottom: 1em;
`;

const StyledPrompt = styled.div`
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1em;
    display: flex;    
`;

const RadioWrapper = styled.div` 
    display: flex;  
    position: relative;
    left: 6px;
`;

const OptionItemWrapper = styled.div`
   display: flex;
   justify-content: center;
   width: 73px;
   top: 5px;   
`;

const RadioButtonLabel = styled.div`
    color: ${ props => props.theme.label };
    font-size: 11px;
    margin-top: 18px;     
`;

const SelectedRadioButtonLabel = styled.div`
    color: ${ props => props.theme.accentText };
    font-size: 11px;
    margin-top: 18px;     
`;

const FirstRadioButtonLabel = styled(RadioButtonLabel)`
    position: relative;
    left: 5px;
`;

const FirstSelectedRadioButtonLabel = styled(SelectedRadioButtonLabel)`
    position: relative;
    left: 5px;
`;

const LastRadioButtonLabel = styled(RadioButtonLabel)`
    position: relative;
    right: 6px;
`;

const LastSelectedRadioButtonLabel = styled(SelectedRadioButtonLabel)`
    position: relative;
    right: 6px;
`;

const LabelWrapper = styled.div`
    padding-top: 15px;
    position: relative;
    right: 46px;
`;

const LikertScaleComponent = (props) => {
  
    const dispatch = useDispatch();
       
    const handleChange = (questionOptionID) => {
        const selectedVal = props.question.options.filter((v, i) => {
            return v.questionOptionID === parseInt(questionOptionID);
        })[0].value;

        const currentVal = props.question.answer;

        const newVal = selectedVal === currentVal ? "" : selectedVal;

        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": newVal} });
    };

    const getLabel = (index, isChecked, val) => {
        if(index === 0){
            if(isChecked){
                return( <FirstSelectedRadioButtonLabel><LabelWrapper><OptionItemWrapper>{val}</OptionItemWrapper></LabelWrapper></FirstSelectedRadioButtonLabel> );
            }else{
                return(<FirstRadioButtonLabel><LabelWrapper><OptionItemWrapper>{val}</OptionItemWrapper></LabelWrapper></FirstRadioButtonLabel>);
            }
        }else if(index === props.question.options.length - 1){
            if(isChecked){
                return( <LastSelectedRadioButtonLabel><LabelWrapper><OptionItemWrapper>{val}</OptionItemWrapper></LabelWrapper></LastSelectedRadioButtonLabel> );
            }else{
                return( <LastRadioButtonLabel><LabelWrapper><OptionItemWrapper>{val}</OptionItemWrapper></LabelWrapper></LastRadioButtonLabel> );
            }
        }else{
            if(isChecked){
                return( <SelectedRadioButtonLabel><LabelWrapper><OptionItemWrapper>{val}</OptionItemWrapper></LabelWrapper></SelectedRadioButtonLabel> );
            }else{
                return( <RadioButtonLabel><LabelWrapper><OptionItemWrapper>{val}</OptionItemWrapper></LabelWrapper></RadioButtonLabel> );
            }
        }
    }

    const getListItems = () => {
        const selectionOptions = props.question;
        let orderedOptions = selectionOptions.options.sort((a, b) => (a.order > b.order) ? -1 : 1)
        const listElements = orderedOptions.map((option, i) => {
            const isChecked = props.question.answer === option.value;
            return(                 
                <RadioWrapper key={option.questionOptionID + '_wrapper'}>
                  <RadioButtonComponent key={option.questionOptionID + '_button'} onChange={handleChange} id={option.questionOptionID} group={props.question.questionID} checked={isChecked} />
                  {getLabel(i, isChecked, option.value)}
                </RadioWrapper>
            )
        })
        return listElements;
    }

    return (
        <StyledRoot>
            <StyledPrompt>
                <PromptText>{ props.question.prompts[0].value}</PromptText>
                <div style={{color: 'red'}}>*</div>
            </StyledPrompt>
            <RadioGroup>
              {getListItems()}
            </RadioGroup>
        </StyledRoot>
    );
}

export default LikertScaleComponent;