import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { up } from 'styled-breakpoints';
import styled from 'styled-components/macro';
import TextInputComponent from '../TextInputComponent/TextInputComponent';

const imgBasePath = process.env.REACT_APP_env_svg_base_path;

const StyledRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;  
`;

const StyledPrompt = styled.div`
  color: ${props => props.theme.primaryText}
`;

const StyledImagePrompt= styled.img`
 display: flex;
`;

const LabelText = styled.div`
    display: flex;
    color: ${ props => props.theme.label };
    font-weight: 400;
    font-size: medium;
    max-width: 16em;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5em;

  div:last-child{
    margin-top: 1.5em;
    width: auto;
  }
`;

const NumberQuestionComponent = (props) => {
  const [qText, setQText] = useState(!props.question.answer ? "" : props.question.answer);
  
  const dispatch = useDispatch();
  
  if(!props.question){
    return (<div></div>);
  }

 const handleOnBlur = (answer) => {
    setQText(answer);
    dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": answer} });
 }

 const getPrompt = (prompts) =>{ 
   let promptNLabel = {};   
    if(prompts && prompts.length > 0){
      if(prompts[0].url) {        
        promptNLabel.image = <StyledImagePrompt src={imgBasePath+prompts[0].url} ></StyledImagePrompt>;
        promptNLabel.value = prompts[0].value;
      }   
     }  
    return promptNLabel;
  } 

 const handleOnChange = (answer) =>{  
    if(!answer.match('^[0-9]*$')) {
       return;
    }
    else{
      setQText(answer)
    }
 }
const prompts = getPrompt(props.question.prompts); 

 return (   
      <StyledRoot className={props.className}> 
        {prompts.image}
        <InputWrapper>
            <LabelText>{prompts.value}</LabelText>
            <TextInputComponent
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                backgroudColor="#cbcbcb"
                value={qText} 
                type="text"
                required={props.required}
                width={60} 
                noLabelPlaceholder={true}                    
            />  
        </InputWrapper>          
    </StyledRoot>
 );   
}

export default NumberQuestionComponent;