import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import RadioButtonComponent from '../RadioButtomComponent/RadioButtonComponent';

const imgBasePath = process.env.REACT_APP_env_svg_base_path;

const StyledRoot = styled.div`
    color: ${props => props.theme.primaryText}
`;

const VerticalRadioGroup = styled(RadioGroup)`

`;

const StyledFormControl = styled(FormControl)`

`;

const StyledOptionImage = styled.img`
    width: 212px;
`;

const StyledPromptText = styled.p`
    margin-top: 0;
`;

const StyledRadioWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledOptionWrapper = styled.div`
    display: flex;
`;

const getPrompts = (prompts) =>{
    if(!prompts){
      return(<div></div>)
    }
    let orderedPrompts = prompts.sort((a, b) => (a.order > b.order) ? 1 : -1)
    return(
      orderedPrompts.map((prompt, i) =>{
        return (<StyledPromptText key={'prompt_'+i}>{prompt.value}</StyledPromptText>);       
      })
    );
  }

const LargeImageOptionsComponent = (props) => {
    const dispatch = useDispatch();
    let value =  !props.question ? "" : props.question.answer;
    if(!props.question)
      {return (<div></div>);}
    

    const handleChange = (questionOptionID) => {
        const selectedVal = props.question.options.filter((v, i) => {
            return v.questionOptionID === parseInt(questionOptionID);
        })[0].optionsUrl;

        const currentVal = props.question.answer;

        const newVal = selectedVal === currentVal ? "" : selectedVal;

        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": newVal} });
    };

    const getListItems = () => {
        const selectionOptions = props.question;
        let orderedOptions = selectionOptions.options.sort((a, b) => (a.order > b.order) ? 1 : -1)
        const listElements = orderedOptions.map((option, i) => {
            const choicevalue = option.value === '' ? option.optionsUrl : option.value;
            return (
                <StyledOptionWrapper key={`option_wrapper_${i}`}>
                    <StyledRadioWrapper>
                        <RadioButtonComponent key={option.questionOptionID + '_button'} onChange={handleChange} id={option.questionOptionID} group={props.question.questionID} checked={props.question.answer === choicevalue} />
                    </StyledRadioWrapper>
                    <label htmlFor={'option_'+ i}><StyledOptionImage alt={option.description} src = {imgBasePath+option.optionsUrl}></StyledOptionImage></label>
                </StyledOptionWrapper>
        )

        })
        return listElements;
    }
    return (
      <div>
        <StyledRoot>
          <StyledFormControl>
            <div>{getPrompts(props.question.prompts)}</div>
            <VerticalRadioGroup aria-label="radiobutton" name="radiobutton" value={value || ''} onChange={handleChange} row={true}>
              {getListItems()}
            </VerticalRadioGroup>
          </StyledFormControl>
        </StyledRoot>
      </div>
    );
}

export default LargeImageOptionsComponent;