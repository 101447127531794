
    import React from 'react';
    import styled from 'styled-components/macro'; 
    import { useDispatch } from 'react-redux';
    import { ThemeProvider } from 'styled-components';
    import DividerComponent from '../DividerComponent/DividerComponent';
    import themes from '../../Themes/themes';
    import SectionBorderComponent from '../ScreeningContainerComponent/SectionBorderComponent';
    import FooterComponent from '../FooterComponent/FooterComponent';   
    import { up } from 'styled-breakpoints';
    import { PrimaryButtonComponent } from '../PrimaryButtonComponent/PrimaryButtonComponent';
    
    const StyledThemeWrapper = styled(ThemeProvider)``;

    const Root = styled.div`
        background-color: ${props => props.theme.background};                     
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    `;

    const TopWrapper = styled.div`                
        background-color: ${props => props.theme.main};
        padding-top: 50px;                
    `;

    const Top = styled.div`
        margin: 0 2em;
        ${up('lg')}{
            width: 70%;
            max-width: 1300px;
            margin: 0 auto;
        }
    `;

    const Header = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;     
        margin-bottom: 70px;           
        ${up('lg')}{
            margin-bottom: 110px;
        }
    `;

    const ProgressBar = styled.img`        
        display: none;
        ${up('xl')}{
            height: 8px;    
            display: inline;        
        }
    `;      

    const Title = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 50px;
    `;

    const TitleText = styled.div`         
        font-weight: bold;
        font-size: 2em;
        color: ${ props => props.theme.card };
        margin-bottom: 16px;           
    `;

    const TextArea = styled.div`
        display: flex;        
        flex-direction: column;    
        flex-grow: 1;
        margin: 0 2em;    
        ${up('lg')}{
            width: 70%;
            max-width: 1300px;
            margin: 0 auto;
        }
    `;        

    const StyledThankyouTitle = styled.div`
        float: left;
        font-size: 2em;
        font-weight: bold;
        color: ${ props => props.theme.main };
    `;

    const PartyImage= styled.img.attrs({
        src: './images/party.svg'
    })`        
        padding-left: 20px;      
        position: relative;
        top: -8px;
    `;

    const GoodbyeText = styled.p`
        display: flex;               
        text-align: left;
        font-size: 1.2em;
        color: ${ props => props.theme.inputText }; 
        max-width: 568px;
        flex-direction: column;
    `;

    const StyledThankyouTileWrapper = styled.div`
       diplay: flex;
    `;
    
    const StyledHeader = styled.img.attrs({
        src: './images/logo_url_white_transparent.png'
    })`
        height: 30px;                
    `;

    const StyledSeparateBar = styled.div`
        dispaly: flex;     
        width: 42px;
        ${up('lg')}{
            width: 52px;
        }
    `;

    const StyledButton = styled(PrimaryButtonComponent)`
        margin-top: 2em;
    `;
    
   
  
    const ThankYouComponent = () => { 
        const dispatch = useDispatch();

        const handleButtonClick = (e) => {
            dispatch({type: 'TRY_AGAIN', data: {}})
        }
      
        return(
            <StyledThemeWrapper theme={ themes['default']}>   
                <Root>                                    
                    <TopWrapper>
                        <Top>
                            <Header>
                                <StyledHeader />  
                                <ProgressBar src="./images/SVGTemplates/completeProgressBar.svg"/>                                            
                            </Header>  
                            <Title>
                                <TitleText> {'Screening Completed!'} </TitleText> 
                                <StyledSeparateBar><DividerComponent /></StyledSeparateBar>                       
                            </Title> 
                        </Top> 
                        <SectionBorderComponent /> 
                    </TopWrapper> 
                    <TextArea>
                        <StyledThankyouTileWrapper>
                            <StyledThankyouTitle>{'Thank You'}</StyledThankyouTitle>
                            <PartyImage />
                        </StyledThankyouTileWrapper>
                        <GoodbyeText>
                        Your screening was successfully 
                        submitted and is no longer 
                        accessible. The results of your 
                        screening have been forwarded to 
                        the hiring staff. Sit tight, you will be
                        hearing from us soon!
                        <StyledButton id={'try-again-btn'} text={'Try Again'} onClick={handleButtonClick}></StyledButton>
                        </GoodbyeText>
                    </TextArea>                                   
                    <FooterComponent />                    
                </Root>  
            </StyledThemeWrapper>      
        )
    }

export default ThankYouComponent;