import React from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components/macro';

const StyledRoot = styled.button`
    background: linear-gradient(48deg, ${props => props.theme.secondary} 0%, ${props => props.theme.tertiary} 100%);
    border-radius: 9999px !important;
    width: 120px;
    height: 41px;
    ${up('sm')}{
        width: 151px;
    }
    color: white;
    font-weight: bold;
    text-transform: unset !important;
    height: 41px;
    font-size: .9em;
    cursor: pointer;
    border: none;
    letter-spacing: .01em;
    position: relative;
    z-index: 1;
    box-shadow: ${props => props.theme.buttonShadow};
    &:before{
        content: "";
        border-radius: 100em;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(48deg, ${props => props.theme.tertiary} 0%, ${props => props.theme.secondary} 100%);
        opacity: 0;
        transition: opacity 1s;
        z-index: -1;
    }
    &:hover::before{
        opacity: 1;
    }
`;

const SmallButton = styled(StyledRoot)`
    width: 100px;
    height: 27px;
`;

export const PrimaryButtonComponent = (props) =>             
    <StyledRoot id={props.id} className={props.className} style={props.innactive ? { opacity: .5 } : { opacity: 1 }} onClick={ props.onClick } disabled={ props.disabled }>
        { props.text }
    </StyledRoot>
            
        


export default PrimaryButtonComponent;