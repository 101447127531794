import React, { useContext, useEffect, useState } from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import TextInputComponent from '../TextInputComponent/TextInputComponent';
import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';
import SecondaryButtonComponent from '../SecondaryButtonComponent/SecondaryButtonComponent';
import RadioButtonComponent from '../RadioButtomComponent/RadioButtonComponent';
import { ThemeContext } from 'styled-components'; 

const LargeInputWrapper = styled.div`
  display: flex;
  min-height: 8.2em;
  width: 48%;  
`;

const PasswordInputWrapper = styled.div`
  display: flex;
  min-height: 8.2em;
  width: 48%;
`;

const FormBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 3em;
  z-index: 100;
  background-color: ${props => props.theme.card};
  padding-left: 7em;
  padding-right: 7em;
  padding-top: 5em;
`;

const Paragraph = styled.div`
    color: ${props => props.theme.primaryText};
    display: flex;
    margin-bottom: 3px;
`;

const InputGroup = styled.div`
    display: flex;
    justify-content: space-between;
`;

const RadioButtons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 18em;
`;

const ButtonRow = styled.div`
    margin-left: 1em;
    margin-right: 1em;
    justify-content: space-between;
    display: flex;
    padding-bottom: 4em;
    padding-top: 4em;
`;

const RadioButtonRow = styled.div`
    margin-top: 2em;
    display: flex;    
`;

const HelperText = styled.div`
    color: ${ props => props.theme.error };
    margin-left: 1.5em;
    margin-top: .17em;
    font-size: .75em;
    min-height: 1.25em;
`;

const TermTitleWrapper = styled.div` 
    display: flex;
    color: ${props => props.theme.accentText};
    font-weight: bold;
`;

const RequiredSymbol = styled.div`
    color: red;  
    margin-bottom: 0;
    padding-left: 0.5em;
`;

const BoldStyle = styled.div`
    font-weight: bold; 
`;
const Paragraphs = styled.div`
`;

const SecondaryFormComponent = (props) =>{
    const themeContext = useContext(ThemeContext);
    const global = useSelector(state => state.global) 
    const onAboutUsChange = props.onAboutUsChange;  
    const aboutUs = props.aboutUs;    
    const isValidPassword = props.isValidPassword;
    const getPasswordHelperText = props.getPasswordHelperText;
    const password = props.password;
    const onPasswordChange = props.onPasswordChange;
    const onPasswordBlur = props.onPasswordBlur;
    const isValidConfirmedPassword = props.isValidConfirmedPassword;
    const confirmedPassword = props.confirmedPassword;
    const onConfirmedPasswordChange = props.onConfirmedPasswordChange;
    const onConfirmedPasswordBlur = props.onConfirmedPasswordBlur;
    const consent = props.consent;   
    const onConsentChange = props.onConsentChange;
    const isValidConsent = props.isValidConsent;
    const getConsentHelperText = props.getConsentHelperText;
    const handleBack = props.handleBack;
    const isValid = props.isValid;
    const handleSubmit = props.handleSubmit;
    const handleOnKeyDown = props.handleOnKeyDown;
    const onEyeClick = props.handleEyeClick;
    const client = props.client;
    const showPwd = global && global.cognitoUser;

    return (
        <FormBox onKeyDown={handleOnKeyDown} tabIndex={0}>
            {!showPwd && 
            <InputGroup>             
            <PasswordInputWrapper>                
                <TextInputComponent
                    id={'pwd-input'}
                    error={ isValidPassword === null ? false : !isValidPassword }
                    helperText={getPasswordHelperText(password)}
                    type={props.passwordType}
                    isEyeButton = {props.isShowPWEyeButton}
                    onChange={onPasswordChange}
                    onEyeClick ={onEyeClick}
                    value={password}
                    onBlur={onPasswordBlur}
                    onFocus ={props.onPWFocus}              
                    required={true}
                    isValid={isValidPassword}
                    label='Password'
                    backgroudColor={themeContext.quarterly}
                />
            </PasswordInputWrapper>
            <PasswordInputWrapper>
                <TextInputComponent
                    id={'cnfrm-input'}
                    error={ isValidConfirmedPassword === null ? false : !isValidConfirmedPassword }
                    helperText={ confirmedPassword ? 'Passwords do not match.' : 'You must confirm your password.'}
                    type={props.confirmPasswordType}
                    isEyeButton = {props.isShowCPWEyeButton}
                    onChange={onConfirmedPasswordChange}
                    onEyeClick ={onEyeClick}
                    value={confirmedPassword}
                    onBlur={onConfirmedPasswordBlur}
                    onFocus = {props.onCPWFocus}
                    required={true}
                    isValid={isValidConfirmedPassword}
                    label='Confirm Password'
                    backgroudColor={themeContext.quarterly}
                />
            </PasswordInputWrapper>
            </InputGroup>       
            }   
            { client != 'createoppcolumbia' ? 
              <LargeInputWrapper>
                <TextInputComponent
                    id={'about-us-ddl'}
                    onChange={onAboutUsChange}                   
                    value={aboutUs}                                
                    label ={"How did you hear about us?"}                          
                    onBlur={onAboutUsChange}                   
                    backgroudColor={themeContext.quarterly}
                />                         
            </LargeInputWrapper> : '' }
            <TermTitleWrapper>Terms and Conditions<RequiredSymbol>*</RequiredSymbol></TermTitleWrapper>
            {
            <Paragraphs>
                {global.config.registration.termsAndConditions.texts.map((v, i) => {
                    return (<Paragraph key={i}>{v}</Paragraph>)
                })}
            <BoldStyle>
                {global.config.registration.termsAndConditions.boldTexts.map((v, i) => {
                    return (<Paragraph key={i}>{v}</Paragraph>)
                })}
            </BoldStyle>
            </Paragraphs>
            }
            <RadioButtonRow>
                <RadioButtons>
                    <RadioButtonComponent id="agree" onChange={onConsentChange} group="consent" label="I accept all terms and conditions" checked={consent === "agree"} isSelectedUnbold ={true} labelStyle={ {fontSize : 'medium', fontWeight : 600}} />                   
                </RadioButtons>
                <HelperText>{isValidConsent === false ? getConsentHelperText(consent) : ''}</HelperText>
            </RadioButtonRow>
            <ButtonRow>
                <SecondaryButtonComponent
                    id={'back-btn'}
                    gradientBorder={true}
                    onClick={handleBack}
                    text={'Back'}
                ></SecondaryButtonComponent>
                <PrimaryButtonComponent
                    id={'submit-btn'}
                    innactive={!isValid}
                    onClick={handleSubmit}
                    text={'Submit'}
                    className={'g-recaptcha'}
                    data-sitekey='6Lfvaz4aAAAAAKj9Lvx8KB-3AqEGt3TrbTVc-x3C'
                    data-callback={handleSubmit}
                    data-action='submit'
                ></PrimaryButtonComponent>
            </ButtonRow>
        </FormBox>
      );
  }

export default SecondaryFormComponent;