import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import DesktopHeaderComponent from '../HeaderComponent/DesktopHeaderComponent';
import FooterComponent from '../FooterComponent/FooterComponent';
import RegistrationPrimaryFormContainer from '../../containers/RegistrationPrimaryFormContainer/RegistrationPrimaryFormContainer';
import ConfirmationComponent from '../ConfirmationComponent/ConfirmationComponent.js';
import DividerComponent from '../DividerComponent/DividerComponent';
import { ThemeContext } from 'styled-components';
import RegistrationSecondaryFormContainer from '../../containers/RegistrationSecondaryFormContainer/RegistrationSecondayFormContainer';
import RegistrationOverviewContainer from '../../containers/RegistrationOverviewContainer/RegistrationOverviewContainer';
import PulseSpinnerComponent from '../PulseSpinnerComponent/PulseSpinnerComponent';

const imgBasePath = process.env.REACT_APP_env_img_base_path;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: space-between;
`;

const HeaderBox = styled.div`
  background-color: ${ props => props.theme.main };
  display: flex;
  flex-direction: column;
  min-height: 20em;
  text-align: center;
  color: white;
  position: relative;
  left: 0;
  border-radius: 3em 3em 0 0;
  padding-top: 1em;
`;

const TemplatedHeaderbox = styled.div`
  background: ${props => props.theme.registrationHeaderBackground};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-height: 20em;
  text-align: center;
  color: white;
  position: relative;
  left: 0;
  border-radius: 3em 3em 0 0;
  padding-top: 1em;
`;

const CreateOpportunityConfirmationHeaderBox = styled.div`
  background: transparent linear-gradient(89deg, #27487E 0%, #124BC5CB 33%, #6294E8 60%, #F79634 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  min-height: 20em;
  text-align: center;
  color: white;
  position: relative;
  left: 0;
  border-radius: 3em 3em 0 0;
  padding-top: 1em;
`;

const RegHeaderText = styled.h1`
  margin-bottom: .3em;
`;

const RegBlurbText = styled.p`
  margin-top: .3em;
`;

const RegBlurb = styled.div`
  margin-top: 1em;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 827px;
`;

 const UpperShadow = styled.div`
  position: relative;
  top: 3.5em;
  box-shadow: 1px 8px 35px ${props => props.theme.formShadow};
  border-radius: 3em;
 `;

 const LowerShadow = styled.div`
  position: relative;
  top: -3.5em;
  box-shadow: 1px 8px 35px ${props => props.theme.formShadow};
  border-radius: 3em;
  background-color: ${props => props.theme.card};
 `;

 const CenteredRow = styled.div`
  display: flex;
  height: 45em;
  justify-content: center;
  border-radius: 3em;
  background-color: white;
`;

const CenteredColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 27%;
`;

const PoweredByCatalyteContainer = styled.div`
  max-width: 50%;
  max-height: 10em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CreateOpportunityLogoContainer = styled.img`
  width: 18em;
  height: 4em;
  margin-top: 7%;
`;

const PoweredByText = styled.p`
  min-width: 7em;
`;

const CatalyteLogoContainer = styled.img`
  max-width: 8em;
  max-height: 2em;
`;

const RegistrationComponent = (props) =>{
    const themeContext = useContext(ThemeContext);
    const [global, setGlobal] = useState(props.globalState);
    let childProps = {...props, themeContext};

    useEffect(() => {
      setGlobal(props.globalState);
    }, [props.globalState])

    const renderHeaderBox = function(){
      if((props.client === 'createoppcolumbia') && props.currentForm === 'confirmation'){
        return(
          <CreateOpportunityConfirmationHeaderBox>
            <RegHeaderText>{headerText}</RegHeaderText>
            <DividerComponent />
            <RegBlurb>
              <RegBlurbText>{blurbText}</RegBlurbText>
            </RegBlurb>
          </CreateOpportunityConfirmationHeaderBox>
        )
      }else if(props.client === 'createoppcolumbia'){
        return(
          <TemplatedHeaderbox>
            <CreateOpportunityLogoContainer src={imgBasePath + global.config.secondaryLogo} />
            <PoweredByCatalyteContainer>
              <PoweredByText>Powered by</PoweredByText>
              <CatalyteLogoContainer src={imgBasePath + global.config.logo} />
            </PoweredByCatalyteContainer>
          </TemplatedHeaderbox>
        )
      }else{
        return(
          <HeaderBox>
            <RegHeaderText>{headerText}</RegHeaderText>
            <DividerComponent />
            <RegBlurb>
              <RegBlurbText>{blurbText}</RegBlurbText>
            </RegBlurb>
          </HeaderBox>
        )
      }
    }

    const renderForm = function(form){

      if(props.showLoadingSpinner){
        return (
          <CenteredRow style={{flexGrow: 1}}>
            <CenteredColumn>
              <PulseSpinnerComponent
                dotColorArray={['#F7C634', '#f6bf55', '#f7ac43', '#f79a34']}
                dotSpeedArray={[1.8, 1.8, 1.8, 1.8]}
                dotDelayArray={[0, 0.2, 0.4, 0.6]}
                size={30}
                positionArray={[5, 10]}
                borderColor={'white'}
              />
            </CenteredColumn>
          </CenteredRow>
          );          
      }
      switch(form){
        case "overview": return(
          <RegistrationOverviewContainer {...childProps} />
        )
        case "primary": return(
          <RegistrationPrimaryFormContainer {...childProps} />
        )
        case "secondary": return(
          <RegistrationSecondaryFormContainer {...childProps} />
        )
        case "confirmation": return(
          <ConfirmationComponent {...childProps} />
        )
        default:
          return (
            <RegistrationPrimaryFormContainer {...childProps} />
        )
      }
    }
    
    const registrationConfig = props.globalState.config && props.globalState.config.registration || {"confirmation": {}, "primary": {}};

    const headerText = props.currentForm === 'confirmation' ? registrationConfig.confirmation.header : registrationConfig.primary.header;
    const blurbText = props.currentForm === 'confirmation' ? registrationConfig.confirmation.blurb : registrationConfig.primary.blurb;
    const imgBasePath = process.env.REACT_APP_env_img_base_path;

    return (
        <StyledRoot>
            <DesktopHeaderComponent logoImageUrl={imgBasePath + props.globalState.config.logo} headerText={headerText} blurbText={blurbText} showLogin={!global.cognitoUser} showLogout={global.cognitoUser} username={global.cognitoUser && global.cognitoUser.username}/>
            <StyledWrapper>
              <UpperShadow>
                { renderHeaderBox() }
              </UpperShadow>
              <LowerShadow>
                { renderForm(props.currentForm) }
              </LowerShadow>
            </StyledWrapper>
            <FooterComponent/>
        </StyledRoot>
    );
  }

export default RegistrationComponent;
